import { PropsWithChildren, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Form, formModelGet, useExtendedReducer } from 'lkh-portal-ui-library';
import { partnerField, PartnerIdProp } from 'models';
import { HealthQuestion, TariffSectionEnum } from 'models/extension-generated';

import { AccompaniedDocumentsDownload } from '../../GeneralData/GeneralQuestions/components/AccompaniedDocumentsDownload.tsx/AccompaniedDocumentsDownload';
import { DiagnosisModal, HealthQuestionDetailJoined } from '../DiagnosesForm/DiagnosesModal';
import { OpenModalButton } from '../DiagnosesForm/OpenModalButton';
import { QuestionsList } from './components/QuestionsList';
import { DisclaimerCheckbox } from 'pages/PrivateHealthInsurance/components/DisclaimerCheckbox';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { QuestionsContextReturnType } from 'pages/PrivateHealthInsurance/context/QuestionsContext';
import { downloadData, downloadFormByReferenceId } from 'utilities/fetchDocument';

const { Input } = Form.Components;

export type QuestionWrapperProps = PropsWithChildren & {
  colClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  hasUnit?: boolean;
  isDisabled?: boolean;
};

type HealthQuestionsProps = PartnerIdProp &
  Omit<QuestionsContextReturnType, 'fetchQuestions'> & {
    isUnder15: boolean;
    applicationStart: string;
  };

export const HealthQuestions = ({
  partnerId,
  isUnder15,
  questions,
  getQuestionLabel,
  getQuestionText,
  getQuestionUnit,
  getQuestionKey,
  applicationStart
}: HealthQuestionsProps) => {
  const { t } = useTranslation('wizardHealth');
  const { state, reducer, dispatch } = useHealthInsuranceContext();
  const { distributeDiagnoses } = useExtendedReducer(dispatch);
  const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);
  const { updateValue } = reducer;

  const healthQuestionsKey = partnerField(partnerId, 'applicationInformation.health');
  const healthQuestions: Array<HealthQuestion> =
    formModelGet(state.model, healthQuestionsKey) || [];

  // Flatten the details and group them by their ids
  const details: Map<string, HealthQuestionDetailJoined> = healthQuestions.reduce(
    (prev, current) => {
      const diagnoses = current.details || [];

      diagnoses.forEach((detail) => {
        prev.set(detail.id || '', {
          ...detail,
          question: {
            id: current.id,
            order: current.order.toString()
          }
        });
      });

      return prev;
    },
    new Map<string, HealthQuestionDetailJoined>()
  );

  const generalQuestions = questions.filter((q) => {
    return !q.sections?.includes(TariffSectionEnum.ZAHN);
  });
  const dentalQuestions = questions.filter((q) => {
    return q.sections?.includes(TariffSectionEnum.ZAHN);
  });

  async function handleDownload() {
    try {
      const response = await downloadFormByReferenceId('K06_31', applicationStart);
      downloadData(response.data, 'Sonstiges Folgen_Anzeigepflichtverletzung_K06_3.pdf');
    } catch (err) {
      //TODO Add error handling
    }
  }

  const acceptsLegalObligationToNotifyKey = partnerField(
    partnerId,
    'applicationInformation.hasLegalObligationToNotify'
  );
  function handleConsentChange(value: boolean) {
    if (!value) {
      updateValue({ key: acceptsLegalObligationToNotifyKey, value: null });
    }
  }

  function renderLegalAcknowledgement() {
    return (
      <div className="grid grid-gap-res grid-mx-res">
        <div>
          <div className="mb-[16px]">
            <AccompaniedDocumentsDownload name={t('downloadDocument')} onClick={handleDownload} />
          </div>
          <div className="py-[18px]">
            <DisclaimerCheckbox
              componentKey={acceptsLegalObligationToNotifyKey}
              onChange={handleConsentChange}
              description={
                <div>
                  <Typography component={'div'} variant="bodyMDBold" className="text-text-100 m-0">
                    {t('section.legal.title')}
                  </Typography>
                  <Typography
                    component={'div'}
                    variant="bodyMDRegular"
                    className="text-text-100 mt-m"
                  >
                    <b className="text-neutral-1000">{t('section.legal.boldText')}</b>
                    {t('section.legal.text')}{' '}
                    <a
                      onClick={handleDownload}
                      className="text-primary-80 cursor-pointer inline underline"
                    >
                      {t('section.legal.link')}
                    </a>
                    .
                  </Typography>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  function renderTextQuestion(key: string) {
    return (
      <>
        <Typography variant="bodyLGRegular" className="grid-res mb-[0px] s:mb-[16px]">
          {getQuestionText(key)}
        </Typography>
        <div className="grid-res mt-[0px]">
          <Input componentKey={getQuestionKey(partnerId, key)} label={getQuestionLabel(key)} />
        </div>
      </>
    );
  }

  function renderNumberQuestion(key: string, props?: QuestionWrapperProps) {
    return (
      <>
        <Typography variant="bodyLGRegular" className="grid-res mb-[0px] s:mb-[16px]">
          {getQuestionText(key)}
        </Typography>
        <div className="grid-res-2 mt-[0px]">
          <Input
            type="number"
            componentKey={getQuestionKey(partnerId, key)}
            placeholder={'0'}
            unitText={getQuestionUnit(key)}
            disabled={props?.isDisabled}
          />
        </div>
      </>
    );
  }

  function renderIntQuestion(key: string, props?: QuestionWrapperProps) {
    return (
      <>
        <Typography variant="bodyLGRegular" className="grid-res mb-[0px] s:mb-[16px]">
          {getQuestionText(key)}
        </Typography>
        <div className="grid-res-2 mt-[0px]">
          <Input
            componentKey={getQuestionKey(partnerId, key)}
            label={getQuestionLabel(key)}
            disabled={props?.isDisabled}
            unitText={getQuestionUnit(key)}
            type="number"
          />
        </div>
      </>
    );
  }
  const showOpenDiagnosesButton = healthQuestions.some(({ answer }) => {
    return answer === true;
  });

  return (
    <Form.Section>
      {renderLegalAcknowledgement()}
      {showOpenDiagnosesButton && (
        <OpenModalButton
          openModal={() => {
            setIsDiagnosisModalOpen(true);
          }}
        />
      )}
      <DiagnosisModal
        diagnoses={details}
        healthQuestions={healthQuestions}
        isOpen={isDiagnosisModalOpen}
        questions={questions}
        onSaveAndClose={(diagnoses) => {
          distributeDiagnoses({ healthKey: healthQuestionsKey, diagnosis: diagnoses });
          setIsDiagnosisModalOpen(false);
        }}
        onDialogClose={() => {
          setIsDiagnosisModalOpen(false);
        }}
      />
      {generalQuestions.length !== 0 && (
        <Form.Section title={t('section.general.title')}>
          <QuestionsList
            questions={generalQuestions}
            partnerId={partnerId}
            isUnder15={isUnder15}
            renderIntQuestion={renderIntQuestion}
            renderTextQuestion={renderTextQuestion}
            renderNumberQuestion={renderNumberQuestion}
            setIsDiagnosisModalOpen={setIsDiagnosisModalOpen}
          />
        </Form.Section>
      )}
      {dentalQuestions.length !== 0 && (
        <Form.Section>
          <div className="grid-mx-res mt-[24px] mb-[-8px]">
            <Typography variant="bodyLGBold">{t('section.dental.title')}</Typography>
          </div>
          <QuestionsList
            questions={dentalQuestions}
            partnerId={partnerId}
            isUnder15={isUnder15}
            renderIntQuestion={renderIntQuestion}
            renderTextQuestion={renderTextQuestion}
            renderNumberQuestion={renderNumberQuestion}
            setIsDiagnosisModalOpen={setIsDiagnosisModalOpen}
          />
        </Form.Section>
      )}
    </Form.Section>
  );
};
