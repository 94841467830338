import CookieConsent from 'react-cookie-consent';

import { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { downloadData, downloadFormByReferenceId } from '../../utilities/fetchDocument';
import { formatInput } from 'utilities/dates';

import styles from './legalFooter.module.scss';

const PRIVACY_DOCUMENT_ID = 'DSEVM_01';
const PRIVACY_DOCUMENT_NAME = 'Datenschutzerklärung LKH_Vermittlerportal_09062023.pdf';
const COOKIE_CONSENT_KEY = 'portal-agent-ui-cookie-check';

const handleDownloadPrivacyDocument = async () => {
  const response = await downloadFormByReferenceId(PRIVACY_DOCUMENT_ID, formatInput(new Date()));
  downloadData(response.data, PRIVACY_DOCUMENT_NAME);
};

export const LEGAL_FOOTER_ID = 'legal-footer';

function FooterDivider() {
  return <Divider className="bg-white-20 h-[16px] mx-[8px]" orientation="vertical" />;
}

export function LegalFooter() {
  const { t } = useTranslation('footer');
  const [showCookies, setShowCookies] = useState(false);

  return (
    <div
      id={LEGAL_FOOTER_ID}
      className="bg-title-80 flex flex-row items-center w-full h-[64px] s:h-[32px] z-100 py-[2px]"
    >
      <div className="s:pl-[40px] m:pl-[80px] pl-[16px] s:pr-[40px] m:pr-[80px] pr-[16px] flex flex-col s:flex-row s:items-center items-start w-full">
        <div className="flex-1">
          <div className="flex flex-row items-center h-[24px] text-[10px] font-normal leading-4 tracking-tight">
            <Typography
              component={'a'}
              variant="bodyXSRegularLink"
              href="https://www.lkh.de/impressum"
              className="text-white-100 hover:text-highlight-80"
              target="_blank"
            >
              {t('imprint')}
            </Typography>
            <FooterDivider />
            <Typography
              component={'a'}
              variant="bodyXSRegularLink"
              href="#"
              className="text-white-100 hover:text-highlight-80"
              onClick={handleDownloadPrivacyDocument}
            >
              {t('privacy')}
            </Typography>
            <FooterDivider />
            <Typography
              component={'a'}
              variant="bodyXSRegularLink"
              href="#"
              className="text-white-100 hover:text-highlight-80"
              onClick={() => setShowCookies(true)}
            >
              {t('cookies.title')}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="bodyXSRegular" className="text-white-100">
            {t('name')}
          </Typography>
        </div>
      </div>
      <CookieConsent
        overlay
        location="bottom"
        visible={showCookies ? 'visible' : 'byCookieValue'}
        cookieName={COOKIE_CONSENT_KEY}
        buttonText={t('cookies.accept')}
        overlayClasses={styles.cookieOverlay}
        containerClasses={styles.cookieContainer}
        contentClasses="surface-neutral-900"
        buttonWrapperClasses="text-center pt-[10px] pb-[40px]"
        ButtonComponent={Button}
        customButtonProps={{ variant: 'contained', size: 'large' }}
        disableButtonStyles
        expires={150}
        onAccept={() => setShowCookies(false)}
      >
        <div className="px-[20px] py-[10px]">
          <Typography variant="headlineXLGRegular" component={'div'} className="mb-[30px]">
            {t('cookies.title')}
          </Typography>
          <Typography component={'div'} variant="bodyMDRegular">
            <Trans
              i18nKey="footer:cookies.text"
              components={{
                privacyDocument: <a href="#" onClick={handleDownloadPrivacyDocument} />
              }}
            />
          </Typography>
        </div>
      </CookieConsent>
    </div>
  );
}
