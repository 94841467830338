import { useEffect, useRef, useState } from 'react';

import { CriteriaType } from '../../../../../models/extension-generated';
import {
  CancelablePromise,
  Criteria,
  PoliciesResponse,
  Policy,
  PolicyService
} from 'models/portal-generated';

import { resolveSortingObject } from './sorting';
import { UseFilter } from 'hooks/useFilter';
import { Paginated } from 'hooks/usePagination';
import { Sortable } from 'hooks/useSort';
import { ContractHook } from 'pages/Contracts/context';

interface UseContracts {
  pagination: Paginated;
  filters: UseFilter;
  sorting: Sortable;
}

export async function getCSVExport(
  filters: UseFilter,
  sorting: Sortable
): CancelablePromise<string> {
  const query: Criteria = {
    page: 0,
    size: 0,
    criteriaType: CriteriaType.AND
  };

  if (sorting.field) {
    query.sort = resolveSortingObject(sorting);
  } else {
    // no sorting field was set, remove it from the query
    delete query.sort;
  }

  if (filters.active.length > 0) {
    query.searchCriteria = filters.active;
  }

  return PolicyService.getCsvExport({ requestBody: query });
}

export function useContracts({ filters, pagination, sorting }: UseContracts): ContractHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<Array<Policy>>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const request = useRef<{ promise: CancelablePromise<PoliciesResponse> | null }>({
    promise: null
  });
  const cancelCurrentRequest = () => {
    request.current?.promise?.cancel();
  };

  const fetchContractsFn = async () => {
    setLoading(true);
    cancelCurrentRequest();

    try {
      const query: Criteria = {
        page: pagination.page,
        size: pagination.size || 10,
        criteriaType: CriteriaType.AND
      };

      if (sorting.field) {
        query.sort = resolveSortingObject(sorting);
      } else {
        delete query.sort;
      }

      if (filters.active.length > 0) {
        query.searchCriteria = filters.active;
      }

      request.current.promise = PolicyService.getPolicies({ requestBody: query });

      const response: PoliciesResponse = await request.current.promise;

      const { results, total } = response;
      setContracts(results);
      setTotal(total);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContractsFn();
  }, [pagination.page, pagination.size, filters.active, sorting.field, sorting.order]);

  return { contracts, total, loading, error, fetchContractsFn };
}
