import classNames from 'classnames';

import { PolicyStatusEnum } from 'models/portal-generated';

import { formatEnum } from 'pages/Contract360Page/utils/formatters';

export const Status = ({ status }: { status?: PolicyStatusEnum }) => {
  const statusColor = (() => {
    switch (status) {
      case PolicyStatusEnum.REACTIVATED:
        return 'bg-success-60';
      case PolicyStatusEnum.ACTIVE:
        return 'bg-success-60';
      case PolicyStatusEnum.CANCELED:
        return 'bg-danger-60';
      default:
        return 'bg-warning-60';
    }
  })();
  return (
    <div className="flex flex-row items-center gap-[8px]">
      <div className={classNames('text-[16px] w-[8px] aspect-square rounded-full', statusColor)} />
      <span>{formatEnum(status as string, 'PolicyStatusEnum', 'contractsEnums')}</span>
    </div>
  );
};
