import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { NeedSituationEnum, PartnerCalculationResult } from 'models/extension-generated';

import { downloadFormByReferenceId } from 'utilities/fetchDocument';
import { formatPrice } from 'utilities/string';

type PriceSummaryProps = {
  isLoading: boolean;
  calculation?: PartnerCalculationResult;
  needSituation?: NeedSituationEnum;
  applicationStart: string;
  addedRiskAmountTotal: number;
};

// TL-5050
const FINAL_YEAR_OF_BRE_AVAILABILITY = 2024;
const FINAL_YEAR_BRE_DOCUMENT = 'K06_123';
const DEFAULT_BRE_DOCUMENT = 'K06_105';

export const PriceSummary = ({
  isLoading,
  calculation,
  needSituation,
  applicationStart
}: PriceSummaryProps) => {
  const { t } = useTranslation('wizardOffer');
  const startYear = moment(applicationStart).year();
  const isCostConsciousPriceAvailable = startYear < FINAL_YEAR_OF_BRE_AVAILABILITY;

  const handleDetailsDownload = async () => {
    const formId =
      startYear === FINAL_YEAR_OF_BRE_AVAILABILITY ? FINAL_YEAR_BRE_DOCUMENT : DEFAULT_BRE_DOCUMENT;
    const doc = await downloadFormByReferenceId(formId, applicationStart);
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank', 'noreferrer');
  };

  return (
    <>
      {needSituation !== NeedSituationEnum.ZVGKV && (
        <div className="bg-surface-80 rounded-[8px] px-[16px] py-[24px] text-text-80">
          {/* Cost conscious price */}
          <div className="flex justify-between pb-[24px]">
            <div className="flex-1 pr-[100px]">
              <Typography variant="bodySMRegular">
                <Trans
                  i18nKey="costConsciousnessPrice"
                  ns="wizardOffer"
                  components={{ bold: <strong /> }}
                />
                <Typography
                  component="a"
                  variant="bodySMRegularLink"
                  onClick={handleDetailsDownload}
                  className="text-primary-80 cursor-pointer"
                >
                  ({t('wizardTariffs:utils.seeDetails')})
                </Typography>
                :
              </Typography>
            </div>
            <div className="pr-[42px]">
              {isLoading ? (
                <Typography variant="bodySMBold" data-testid="price">
                  -,--
                </Typography>
              ) : (
                <Typography variant="bodySMBold" data-testid="price">
                  {formatPrice(calculation?.calculationMonthly?.costConsciousAmount)}
                </Typography>
              )}
            </div>
          </div>

          {startYear === FINAL_YEAR_OF_BRE_AVAILABILITY && (
            <div className="flex-1 pr-[100px] pb-[24px]">
              <Typography variant="bodySMRegular">
                <Trans
                  i18nKey="lastYearAvailabilityWarning"
                  ns="wizardOffer"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
          )}

          {/* Tax deductible */}
          {/* Not valid and visible for final year of BRE */}
          {isCostConsciousPriceAvailable && (
            <div className="flex justify-between pb-[24px]">
              <div className="flex-1 pr-[100px]">
                <Typography variant="bodySMRegular">
                  <Trans
                    i18nKey="taxDeductiblePrice"
                    ns="wizardOffer"
                    components={{ bold: <strong /> }}
                    values={{
                      year: startYear
                    }}
                  />
                </Typography>
                <Typography
                  component="a"
                  variant="bodySMRegularLink"
                  onClick={handleDetailsDownload}
                  className="text-primary-80 cursor-pointer"
                >
                  {t('wizardTariffs:utils.seeDetails')}
                </Typography>
                .
              </div>
              <div className="pr-[42px]">
                {isLoading ? (
                  <Typography variant="bodySMBold" data-testid="price">
                    -,--
                  </Typography>
                ) : (
                  <Typography variant="bodySMBold">
                    {formatPrice(calculation?.calculationMonthly?.taxDeductibleAmount)}
                  </Typography>
                )}
              </div>
            </div>
          )}

          {/* No benefits */}
          <div className="flex justify-between">
            <div className="flex-1 pr-[100px]">
              <Typography variant="bodySMRegular">
                <Trans
                  i18nKey="noBenefitsPrice"
                  ns="wizardOffer"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
            <div className="pr-[42px]">
              {isLoading ? (
                <Typography variant="bodySMBold" data-testid="price">
                  -,--
                </Typography>
              ) : (
                <Typography variant="bodySMBold">
                  {formatPrice(calculation?.calculationMonthly?.noBenefitsAmount)}
                </Typography>
              )}
            </div>
          </div>
          <Divider className="my-[24px]" />
          <Typography variant="bodySMRegular">{t('wizardOffer:premiumRefundsNote')}</Typography>
        </div>
      )}
    </>
  );
};
