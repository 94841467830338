import { useState } from 'react';

import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button, { ButtonClasses } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField } from 'models';

import { useApplicaitionPersistance } from '../../hooks/useApplicationPersistance';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import {
  downloadOfferPages,
  downloadProtocolPages
} from 'pages/PrivateHealthInsurance/utils/actions';
import { downloadOfferDocument } from 'utilities/fetchContract';
import { downloadData, downloadFormByReferenceId } from 'utilities/fetchDocument';

type DownloadButtonProps = {
  currentPage: WizardRoutesEnum;
};

export const DownloadButton = ({ currentPage }: DownloadButtonProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state } = useHealthInsuranceContext();
  const { save } = useApplicaitionPersistance();

  const [isDownloadingProtocol, setIsDownloadingProtocol] = useState(false);
  const [isDownloadingOffer, setIsDownloadingOffer] = useState(false);

  const downloadProtocol = async () => {
    setIsDownloadingProtocol(true);
    try {
      const response = await downloadFormByReferenceId('BP_01', state.model.applicationStart || '');
      downloadData(response.data, `${t('actions.downloadProt.fileName')}.pdf`);
    } finally {
      setIsDownloadingProtocol(false);
    }
  };

  const downloadOffer = async () => {
    setIsDownloadingOffer(true);
    const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
    if (!applicationStart) {
      toast.warning(t<string>('common:toastMessages.missingApplicationStartDate'), {
        delay: 500
      });
      setIsDownloadingOffer(false);
      return;
    }
    try {
      const cloned = cloneDeep(state.model);
      await save(cloned);
      await downloadOfferDocument(cloned);
    } finally {
      setIsDownloadingOffer(false);
    }
  };
  const buttonClasses: Partial<ButtonClasses> = {
    colorPrimary: 'text-white-100 m:text-primary-80 border-white-100 m:border-primary-80',
    disabled: 'text-white-100 m:text-primary-80'
  };

  return (
    <>
      {downloadProtocolPages.includes(currentPage) && (
        <Button
          className="mt-[0px] m:mt-[16px]"
          variant="outlined"
          classes={buttonClasses}
          size="small"
          disabled={isDownloadingProtocol}
          startIcon={
            isDownloadingProtocol ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <FileDownloadOutlinedIcon />
            )
          }
          onClick={downloadProtocol}
        >
          {t('actions.downloadProt.label')}
        </Button>
      )}
      {downloadOfferPages.includes(currentPage) && (
        <Button
          className="mt-[0px] m:mt-[16px]"
          size="small"
          classes={buttonClasses}
          variant="outlined"
          disabled={isDownloadingOffer}
          startIcon={
            isDownloadingOffer ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <FileDownloadOutlinedIcon />
            )
          }
          onClick={downloadOffer}
        >
          {t('actions.downloadOffer.label')}
        </Button>
      )}
    </>
  );
};
