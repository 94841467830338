import { useMemo, useState } from 'react';

import { format, isFirstDayOfMonth, isValid, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

import { DarkTooltip, DropdownOption, Form, formModelGet } from 'lkh-portal-ui-library';
import {
  applicationField,
  federalRepublicOptionsWithGeneric,
  needSituationOptions,
  partnerField,
  workingDoctorRelationshipOptions
} from 'models';
import {
  FederalStateEnum,
  NeedSituationEnum,
  Partner,
  PersonRoleEnum
} from 'models/extension-generated';

import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { getFirstOfNextMonth } from 'utilities/dates';
import {
  getInsuranceStartDatesOther,
  getInsuranceStartDatesZVGKV
} from 'utilities/getInsuranceStartDates';

const { Dropdown, Radio, YesNo, Field } = Form.Components;

export const EmploymentData = () => {
  const { t } = useTranslation('wizardTariffs', { keyPrefix: 'employmentData' });
  const { state, partnerId, reducer } = useHealthInsuranceContext();
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const { clearTariffInformation, clearTariffs, updateMultipleValues, updateValue } = reducer;

  const partners: Array<Partner> = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const isCurrentPartnerFirst = partners[0]?.id === partnerId;

  const applicationStartPath = applicationField('applicationStart');
  const {
    value: applicationStartKeyValue,
    hasError: applicationStartKeyHasError,
    message: applicationStartKeyMsg,
    isDirty: applicationStartKeyisDirty,
    removeError: applicationStartKeyRemoveError
  } = Form.hooks.useFormComponent(applicationStartPath);
  const [applicationStartDateError, setApplicationStartDateError] = useState<string | null>(null);

  const federalStatePath = partnerField(partnerId, 'applicationInformation.federalState');
  const needSituationPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.needSituation'
  );

  const federalAidProvider = formModelGet(state.model, federalStatePath);
  const needSituation: NeedSituationEnum = formModelGet(state.model, needSituationPath);
  const availableStartDates: Array<DropdownOption> = useMemo(() => {
    if (needSituation === NeedSituationEnum.ZVGKV) {
      return getInsuranceStartDatesZVGKV();
    }
    return getInsuranceStartDatesOther();
  }, [needSituation]);

  const applicationStartValue = applicationStartKeyValue
    ? parse(applicationStartKeyValue.toString(), 'yyyy-MM-dd', new Date())
    : null;
  const minDate = parse(availableStartDates[0].value.toString(), 'yyyy-MM-dd', new Date());
  const maxDate = parse(
    availableStartDates[availableStartDates.length - 1].value.toString(),
    'yyyy-MM-dd',
    new Date()
  );

  const showClaimQuestion = (() => {
    if (!federalAidProvider) return false;

    if (needSituation === NeedSituationEnum.BU_P || needSituation === NeedSituationEnum.BAU_P) {
      return true;
    }

    if (
      needSituation === NeedSituationEnum.KB &&
      [FederalStateEnum.HESSEN, FederalStateEnum.SCHLESWIG_HOLSTEIN].includes(federalAidProvider)
    ) {
      return true;
    }

    return false;
  })();

  const showPreExistingParentInsurance = NeedSituationEnum.SU_S === needSituation;
  const showNeedSituationQuestion = [
    NeedSituationEnum.BU_P,
    NeedSituationEnum.BAU_P,
    NeedSituationEnum.KB
  ].includes(formModelGet(state.model, needSituationPath));

  const showDoctorRelationshipQuestion = needSituation === NeedSituationEnum.HMU_P;

  const claimPercentsDropdownOptions: Array<DropdownOption> = (() => {
    function createClaimOption(value: number): DropdownOption {
      return {
        value: value.toString(),
        label: `${value}%`
      };
    }

    if (showClaimQuestion) {
      if (federalAidProvider === FederalStateEnum.BREMEN) {
        return [
          createClaimOption(50),
          createClaimOption(55),
          createClaimOption(60),
          createClaimOption(65),
          createClaimOption(70),
          createClaimOption(80)
        ];
      }

      if (federalAidProvider === FederalStateEnum.HESSEN) {
        return [
          createClaimOption(50),
          createClaimOption(55),
          createClaimOption(60),
          createClaimOption(65),
          createClaimOption(70)
        ];
      }

      if (federalAidProvider === FederalStateEnum.SCHLESWIG_HOLSTEIN) {
        if (needSituation === NeedSituationEnum.KB) {
          return [createClaimOption(80), createClaimOption(90)];
        }
        if (needSituation === NeedSituationEnum.BU_P) {
          return [createClaimOption(50), createClaimOption(70), createClaimOption(90)];
        }
      }

      return [createClaimOption(50), createClaimOption(70)];
    }

    return [];
  })();

  function handleNeedSituationChange(value: DropdownOption[] | undefined) {
    const comprehensiveHealthTransferValueKey = partnerField(
      partnerId,
      `applicationInformation.comprehensiveHealthTransferValue`
    );
    const referenceValuePPVKey = partnerField(
      partnerId,
      `applicationInformation.referenceValuePPV`
    );
    const fundsFromGZKey = partnerField(partnerId, `applicationInformation.fundsFromGZ`);
    clearTariffs({ partnerId });
    clearTariffInformation({ partnerId });

    /**
     * Reset start date in following cases:
     * - current partner is the first partner
     * - Selected date is NOT first of the month AND selected NeedSituation IS ZVGKV
     *  */
    if (
      isCurrentPartnerFirst &&
      !isFirstDayOfMonth(applicationStartValue || 0) &&
      value?.[0]?.value === NeedSituationEnum.ZVGKV
    ) {
      updateValue({ key: applicationStartPath, value: getFirstOfNextMonth() });
    }
    applicationStartKeyRemoveError(applicationStartPath);

    updateMultipleValues([
      { key: federalStatePath, value: undefined },
      { key: comprehensiveHealthTransferValueKey, value: undefined },
      { key: referenceValuePPVKey, value: undefined },
      { key: fundsFromGZKey, value: undefined }
    ]);
  }

  function handleFederalStateChange() {
    clearTariffs({ partnerId });
    clearTariffInformation({ partnerId, fields: ['hasClaimSince2012', 'claimAmount'] });
    updateMultipleValues([
      { key: 'applicationInformation.tariffInformation.claimAmou', value: undefined }
    ]);
  }

  function handleTariffRelevantFieldChange() {
    clearTariffs({ partnerId });
  }

  const renderInsuranceStartTooltip = () =>
    !isCurrentPartnerFirst ? (
      <>
        <Typography component={'div'} variant="bodySMBold" className="text-white-100">
          {t('insuranceStart.tooltip.title')}
        </Typography>
        <Typography component={'div'} variant="bodySMRegular" className="text-white-100">
          {t('insuranceStart.tooltip.text')}
        </Typography>
      </>
    ) : undefined;

  const shouldDisableDate = (date: Date): boolean => {
    if (needSituation === NeedSituationEnum.ZVGKV) return !isFirstDayOfMonth(date);
    return false;
  };

  const onAppStartDateChange = (
    val: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (context.validationError) {
      setApplicationStartDateError(t('insuranceStart.customErrorMsg'));
    } else {
      setApplicationStartDateError(null);
    }
    handleTariffRelevantFieldChange();
    const date = val && isValid(val) ? format(val, 'yyyy-MM-dd') : '';
    updateValue({
      key: applicationStartPath,
      value: date
    });
  };

  return (
    <Form.Section title={t('title')}>
      <div className="grid-res-2">
        <div>
          <Dropdown
            componentKey={needSituationPath}
            options={needSituationOptions}
            label={t('needSituation.label')}
            placeholder={t('needSituation.placeholder')}
            onChange={(value) => {
              handleNeedSituationChange(value);
            }}
          />
        </div>
        <div>
          <DarkTooltip title={renderInsuranceStartTooltip()} followCursor placement="top-start">
            <span>
              <Field
                error={applicationStartKeyHasError || !!applicationStartDateError}
                message={applicationStartKeyMsg || applicationStartDateError}
              >
                <DatePicker
                  className="w-full"
                  disabled={!isCurrentPartnerFirst}
                  defaultValue={parse(getFirstOfNextMonth(), 'yyyy-MM-dd', new Date())}
                  value={applicationStartValue}
                  label={t('insuranceStart.label')}
                  shouldDisableDate={shouldDisableDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={onAppStartDateChange}
                  slotProps={{
                    textField: {
                      error:
                        (applicationStartKeyHasError && applicationStartKeyisDirty) ||
                        !!applicationStartDateError
                    }
                  }}
                />
              </Field>
            </span>
          </DarkTooltip>
        </div>
      </div>
      {showPreExistingParentInsurance && (
        <div className="grid-res-2">
          <div>
            <YesNo
              componentKey={partnerField(
                partnerId,
                'applicationInformation.tariffInformation.isParentInsuredInLKH'
              )}
              question={t('isParentInsuredInLKH.question')}
              truthyLabel={t('isParentInsuredInLKH.true')}
              falsyLabel={t('isParentInsuredInLKH.false')}
              onChange={handleTariffRelevantFieldChange}
            />
          </div>
        </div>
      )}
      <div className="grid-res-2">
        {showNeedSituationQuestion && (
          <div>
            <Dropdown
              label={t('federalState.label')}
              placeholder={t('federalState.placeholder')}
              componentKey={partnerField(partnerId, 'applicationInformation.federalState')}
              options={federalRepublicOptionsWithGeneric}
              onChange={handleFederalStateChange}
            />
          </div>
        )}
        {showClaimQuestion && (
          <div>
            <Dropdown
              label={t('claimAmount.label')}
              placeholder={t('claimAmount.placeholder')}
              componentKey={partnerField(
                partnerId,
                'applicationInformation.tariffInformation.claimAmount'
              )}
              options={claimPercentsDropdownOptions}
              onChange={handleTariffRelevantFieldChange}
            />
          </div>
        )}
      </div>
      {showDoctorRelationshipQuestion && (
        <>
          <div className="grid-res-2">
            <div>
              <Typography variant="bodyLGRegular" className="mb-[0px] s:mb-[16px]">
                {t('workingDoctorRelationship.question')}
              </Typography>
              <Radio
                className="ml-[16px]"
                componentKey={partnerField(
                  partnerId,
                  `applicationInformation.tariffInformation.workingDoctorRelationship`
                )}
                options={workingDoctorRelationshipOptions}
                onChange={handleTariffRelevantFieldChange}
              />
            </div>
          </div>
        </>
      )}
    </Form.Section>
  );
};
