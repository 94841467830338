import { axios } from 'services/axios';

import { Application } from 'models/extension-generated';

import { downloadData } from 'utilities/fetchDocument';

export const downloadContract = async (applicationId: string) => {
  const response = await axios.get(`/api/application/${applicationId}/order`, {
    responseType: 'arraybuffer',
    headers: {
      accept: 'application/octet-stream'
    }
  });

  downloadData(response.data, `${applicationId}.pdf`);
};

export const downloadOfferDocument = async (application: Application) => {
  const response = await axios.post(
    '/api/application/offer',
    { ...application, id: application.id },
    { responseType: 'blob' }
  );

  downloadData(response.data, `${application.id}-VS.pdf`);
};
