import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { getAgentIdFilterOptions, model } from '../models';
import { SearchOperationEnum } from 'models/portal-generated';

import MultiSelectAll from '../Multiselect';
import { useUser } from 'hooks/useUser';
import { FilterGroupsEnum } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';

export const ExpandedContractFilters = () => {
  const { t } = useTranslation('contract');

  const {
    filters,
    handleListFilterChange,
    handleRangeChange,
    handleDateRangeChange,
    handleTextFilterChange,
    getSelectedValue,
    getSelectedValues
  } = useContractPageContext();
  const { agentIds } = useUser();
  const agentIdOptions = getAgentIdFilterOptions(agentIds).list;
  const matchCode = getSelectedValue(FilterGroupsEnum.MatchCode);
  const street = getSelectedValue(FilterGroupsEnum.MainContactStreet) || '';
  const houseNumber = getSelectedValue(FilterGroupsEnum.MainContactHouseNumber) || '';
  const productGroup = getSelectedValues(FilterGroupsEnum.TariffGroupId, model.productGroup.list);
  const selectedAgentIds = getSelectedValues(FilterGroupsEnum.AgentId, agentIdOptions);
  const notProductGroup = getSelectedValues(
    FilterGroupsEnum.NotTariffGroupId,
    model.productGroup.list
  );
  const organizationUnit = getSelectedValues(
    FilterGroupsEnum.OrganizationUnit,
    model.organizationUnit.list
  );

  const birthDate = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.PolicyHolderBirthDate;
  });
  const fromBirthDate = birthDate?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
  )?.value;
  const toBirthDate = birthDate?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
  )?.value;
  const documented = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.Documented;
  });
  const fromDocumented = documented?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
  )?.value;
  const toDocumented = documented?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
  )?.value;
  const mainContactValidFilter = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.MainContactValidFrom;
  });
  const mainContactValidFrom = mainContactValidFilter?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
  )?.value;

  return (
    <>
      <div className="w-full hidden s:grid s:grid-cols-5 gap-[24px] items-end">
        <TextField
          value={matchCode}
          label={t('filter.matchCode')}
          placeholder={t('filter.matchCode')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MatchCode, value.toString());
          }}
        />
        <DatePicker
          value={fromBirthDate ? new Date(fromBirthDate) : null}
          label={t('filter.fromDateBirth')}
          onChange={(value, context) => {
            if (!context.validationError) {
              handleDateRangeChange({
                from: value,
                to: toBirthDate ? new Date(toBirthDate) : undefined,
                groupId: FilterGroupsEnum.PolicyHolderBirthDate
              });
            }
          }}
        />
        <TextField
          value={street}
          label={t('filter.street')}
          placeholder={t('filter.street')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactStreet, value.toString());
          }}
        />
        <MultiSelectAll
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.NotTariffGroupId,
              SearchOperationEnum.NOT_IN,
              model.productGroup.list
            );
          }}
          value={notProductGroup}
          label={t('filter.notProductGroup')}
          items={model.productGroup.list}
        />
        <DatePicker
          value={fromDocumented ? new Date(fromDocumented) : null}
          label={t('filter.fromDocumented')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: value,
              to: toDocumented ? new Date(toDocumented) : undefined,
              groupId: FilterGroupsEnum.Documented
            });
          }}
        />
        {/* placeholder to ensure empty slot */}
        <div />
        <DatePicker
          value={toBirthDate ? new Date(toBirthDate) : null}
          label={t('filter.toDateBirth')}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleDateRangeChange({
              from: fromBirthDate ? new Date(fromBirthDate) : undefined,
              to: value,
              groupId: FilterGroupsEnum.PolicyHolderBirthDate
            });
          }}
        />
        <TextField
          value={houseNumber}
          label={t('filter.houseNumber')}
          placeholder={t('filter.houseNumber')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactHouseNumber, value);
          }}
        />
        <MultiSelectAll
          value={productGroup}
          label={t('filter.productGroup')}
          items={model.productGroup.list}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.TariffGroupId,
              SearchOperationEnum.IN,
              model.productGroup.list
            );
          }}
        />
        <DatePicker
          value={toDocumented ? new Date(toDocumented) : null}
          label={t('filter.toDocumented')}
          onChange={(value) => {
            const date: string = value && !isNaN(value.getTime()) ? value?.toISOString() : '';
            handleRangeChange({
              to: date,
              from: fromDocumented,
              groupId: FilterGroupsEnum.Documented
            });
          }}
        />
        <div />
        <div />
        <DatePicker
          value={mainContactValidFrom ? new Date(mainContactValidFrom) : null}
          label={t('filter.mainContactValidFrom')}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleDateRangeChange({
              from: value,
              groupId: FilterGroupsEnum.MainContactValidFrom
            });
          }}
        />
        <div />
        <MultiSelectAll
          value={organizationUnit}
          label={t('filter.organizationUnit')}
          items={model.organizationUnit.list}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.OrganizationUnit,
              SearchOperationEnum.IN,
              model.organizationUnit.list
            );
          }}
        />
      </div>

      {/* Small vieport */}
      <div className="w-full grid grid-cols-3 s:hidden gap-[24px] items-end">
        <TextField
          value={matchCode}
          label={t('filter.matchCode')}
          placeholder={t('filter.matchCode')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MatchCode, value.toString());
          }}
        />
        <TextField
          value={street}
          label={t('filter.street')}
          placeholder={t('filter.street')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactStreet, value.toString());
          }}
        />
        <MultiSelectAll
          items={agentIdOptions}
          value={selectedAgentIds}
          label={t('filter.agentId')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.AgentId,
              SearchOperationEnum.IN,
              agentIdOptions
            );
          }}
        />
        <DatePicker
          value={fromDocumented ? new Date(fromDocumented) : null}
          label={t('filter.fromDocumented')}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleDateRangeChange({
              from: value,
              to: toDocumented,
              groupId: FilterGroupsEnum.Documented
            });
          }}
        />
        <TextField
          value={houseNumber}
          label={t('filter.houseNumber')}
          placeholder={t('filter.houseNumber')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactHouseNumber, value);
          }}
        />
        <TextField
          value={organizationUnit}
          label={t('filter.organizationUnit')}
          placeholder={t('filter.organizationUnit')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.OrganizationUnit, value);
          }}
        />
        <DatePicker
          value={toDocumented ? new Date(toDocumented) : null}
          label={t('filter.toDocumented')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              to: value,
              from: fromDocumented,
              groupId: FilterGroupsEnum.Documented
            });
          }}
        />
        <DatePicker
          value={mainContactValidFrom ? new Date(mainContactValidFrom) : null}
          label={t('filter.mainContactValidFrom')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: value,
              groupId: FilterGroupsEnum.MainContactValidFrom
            });
          }}
        />
      </div>
    </>
  );
};
