import { ReactNode, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TaskIcon from '@mui/icons-material/Task';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { WhiteLogo } from './Logo';
import { SupportButton } from 'pages/PrivateHealthInsurance/components/SupportButton/SupportButton';

import styles from './NavigationBar.module.scss';

type MenuItemType = {
  name: string;
  link: string;
  icon: {
    default: ReactNode;
    active: ReactNode;
  };
  isActive?: boolean;
};

const LOCAL_STORAGE_KEY = 'IS_MENU_COLLAPSED_STATE';

function getStoredIsCollapsed() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || 'false');
}

function MenuItem({
  item,
  isSidebarOpen,
  isMenuOpen,
  navigateTo
}: {
  item: MenuItemType;
  isSidebarOpen: boolean;
  isMenuOpen: boolean;
  navigateTo: NavigateFunction;
}) {
  const [activeItem, setActiveItem] = useState('');
  const debounceSetHovered = debounce(setActiveItem, 100);

  return (
    <div
      className={classNames(
        'w-full hover:bg-white-20 cursor-pointer',
        item.isActive ? 'bg-white-40' : ''
      )}
      onMouseEnter={() => debounceSetHovered(item.link)}
      onMouseLeave={() => debounceSetHovered('')}
      onClick={() => {
        navigateTo(item.link);
      }}
    >
      <div
        className={
          item.isActive
            ? 'absolute w-[4px] h-[56px] bg-white-100 rounded-r-[5px]'
            : 'active:bg-white-40'
        }
      />
      <Typography
        variant="bodySMBold"
        className={classNames(
          'flex items-center justify-center space-x-[16px] h-[56px] mx-[24px] text-white-100',
          {
            [styles.menuDesktop]: !isSidebarOpen
          },
          styles.menuMobile
        )}
      >
        <span>
          {item.isActive || activeItem === item.link ? item.icon.active : item.icon.default}
        </span>
        <span
          className={classNames({
            hidden: isSidebarOpen && !isMenuOpen
          })}
        >
          {item.name}
        </span>
      </Typography>
    </div>
  );
}

export const NavigationBar = () => {
  const { t } = useTranslation('navigation');
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const [isSidebarOpen, setIsSidebarOpen] = useState(getStoredIsCollapsed());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const agentId = keycloak?.tokenParsed?.agentId || '';
  const agentName = keycloak?.tokenParsed?.name || '';

  const menuItems: Array<MenuItemType> = useMemo(() => {
    const linkDefinitions: Array<MenuItemType> = [
      {
        name: t('items.dashboard'),
        link: '/dashboard',
        icon: {
          default: <TaskOutlinedIcon />,
          active: <TaskIcon />
        }
      },
      {
        name: t('items.products'),
        link: '/produkte',
        icon: {
          default: <CategoryOutlinedIcon />,
          active: <CategoryIcon />
        }
      },
      {
        name: t('items.inventory'),
        link: '/bestand',
        icon: {
          default: <Groups2OutlinedIcon />,
          active: <Groups2Icon />
        }
      },
      {
        name: t('items.form'),
        link: '/formulare',
        icon: {
          default: <InsertDriveFileOutlinedIcon />,
          active: <InsertDriveFileIcon />
        }
      },
      {
        name: t('items.messages'),
        link: '/neuigkeiten',
        icon: {
          default: <ArticleOutlinedIcon />,
          active: <ArticleIcon />
        }
      },
      {
        name: t('items.profile'),
        link: '/profil',
        icon: {
          default: <PersonOutlineOutlinedIcon />,
          active: <PersonIcon />
        }
      }
    ];
    const extractedLocation = '/' + location.pathname.split('/')[1];
    const current = linkDefinitions.find((item) => item.link === extractedLocation);
    if (current) {
      current.isActive = true;
    }

    return linkDefinitions;
  }, [location]);

  const handleToggleCollapse = () => {
    const collapsedValue = !isSidebarOpen;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(collapsedValue));
    setIsSidebarOpen(collapsedValue);
  };

  const handleToggleResponsiveCollapse = () => {
    setIsMenuOpen((current) => !current);
  };

  return (
    <aside
      className={classNames(
        styles.navigationBar,
        'flex flex-col justify-start z-[100] bg-navigation-80 text-white-100 sticky top-0 h-screen',
        {
          [styles.collapsed]: isSidebarOpen,
          [styles.open]: isMenuOpen
        }
      )}
    >
      <div className="flex flex-col items-center mt-[16px] w-full h-full">
        <div className="flex flex-row items-center justify-between w-full mb-[36px]">
          <div className={classNames(styles.logo, 'h-[28px] ml-[24px]')}>
            <WhiteLogo />
          </div>
          <div
            data-testid="collapse-icon"
            className={classNames(
              'flex items-center justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer hover:bg-white-20 active:bg-white-40 mr-[24px] hidden-md-down'
            )}
            onClick={handleToggleCollapse}
          >
            {isSidebarOpen ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
          </div>
          <div
            data-testid="collapse-icon-responsive"
            className="flex items-center justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer hover:bg-white-20 active:bg-white-40 mr-[24px] hidden-md-up"
            onClick={handleToggleResponsiveCollapse}
          >
            <MenuIcon />
          </div>
        </div>
        <div className="flex flex-col gap-12 mb-[40px] w-full relative">
          {menuItems.map((item) => (
            <MenuItem
              key={item.name}
              item={item}
              isSidebarOpen={isSidebarOpen}
              isMenuOpen={isMenuOpen}
              navigateTo={navigate}
            />
          ))}
        </div>
      </div>
      <div className="left-[24px] mb-[24px] mx-[24px]">
        <div
          className={classNames(
            'invisible m:visible mb-[24px] w-full duration-200 delay-100',
            isSidebarOpen ? 'opacity-0 ' : 'opacity-100'
          )}
        >
          <SupportButton showText />
        </div>

        <DarkTooltip
          title={
            <>
              <div>{agentName}</div> <br />
              <div>{agentId}</div>
            </>
          }
          arrow
          placement="right"
        >
          <div className="flex justify-between items-center h-[40px]">
            <div className="flex space-x-2">
              <div className="flex items-center justify-center w-[40px]">
                <Avatar className="w-[32px] h-[32px] bg-white-20">
                  <PersonOutlineOutlinedIcon className="w-[16px] h-[16px]" />
                </Avatar>
              </div>
              <div
                className={classNames(
                  'grid-cols-subgrid duration-200 delay-100',
                  isSidebarOpen ? 'opacity-0' : 'opacity-100',
                  { [styles.menuMode]: isMenuOpen }
                )}
              >
                <Typography
                  variant="bodySMBold"
                  component={'div'}
                  className="col-start-1 text-white-100 text-left align-middle w-[100px]"
                >
                  {agentName}
                </Typography>
                <Typography
                  variant="bodySMRegular"
                  component={'div'}
                  className="col-start-2 text-white-80 text-left w-[100px] truncate"
                >
                  {agentId}
                </Typography>
              </div>
            </div>
            <div
              data-testid="logoutIcon"
              className={classNames(
                'flex items-center gap-[8px] justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer hover:bg-white-20 active:bg-white-40',
                { [styles.menuMode]: isMenuOpen }
              )}
              onClick={() => keycloak.logout()}
            >
              <LogoutIcon />
            </div>
          </div>
        </DarkTooltip>
      </div>
    </aside>
  );
};
