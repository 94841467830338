import { PortalLayout } from 'layout/portal';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import { SimpleTable } from 'components/SimpleTable';

export const ProfilePage = () => {
  const { t } = useTranslation('profile');
  const { keycloak } = useKeycloak();

  const personalInfo = [
    { label: t('firstName'), value: keycloak?.tokenParsed?.given_name || '' },
    { label: t('lastName'), value: keycloak?.tokenParsed?.family_name || '' }
  ];
  const intermediaryInfo = [{ label: t('agentId'), value: keycloak?.tokenParsed?.agentId || '' }];

  return (
    <PortalLayout>
      <div className="px-s py-[13px]">
        <div className="grid grid-cols-1 m:grid-cols-2">
          <div className="p-16">
            <SimpleTable title={t('personData')} rows={personalInfo} />
          </div>
          <div className="p-16">
            <SimpleTable title={t('intermediaryData')} rows={intermediaryInfo} />
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
