import { ColDef } from 'ag-grid-community';
import i18n from 'i18next';

import { TableTooltips } from '../../TableTooltip';
import { ShowDownloadForm } from 'components/ShowDownloadForm/ShowDownloadForm';

export const columnDefs: ColDef[] = [
  {
    field: 'type',
    resizable: true,
    flex: 1.5,
    headerName: i18n.t('table.type', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-bold text-text-80'
  },
  {
    field: 'name',
    resizable: true,
    flex: 4,
    headerName: i18n.t('table.name', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    tooltipField: 'name',
    tooltipComponent: TableTooltips.DocumentName,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80'
  },
  {
    field: 'id',
    headerName: i18n.t('table.code', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    resizable: true,
    sortable: true,
    flex: 2,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80'
  },
  {
    field: 'version',
    flex: 1,
    resizable: true,
    headerName: i18n.t('table.version', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80'
  },
  {
    headerName: '',
    headerClass:
      'border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    flex: 1,
    field: 'showDownload',
    cellRenderer: (params: { value: readonly [formId: string, formName: string] }) => (
      <span className="ag-action-button">
        <ShowDownloadForm documentInfo={params.value} />
      </span>
    ),
    cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80'
  }
];
