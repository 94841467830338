/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { v4 as uuid } from 'uuid';

import { Application, Partner, PersonRoleEnum } from 'models/extension-generated';

import { getFirstOfNextMonth } from '../../../utilities/dates';

export function createApplication(): Application {
  return {
    applicationStart: getFirstOfNextMonth(),
    inSignSessionId: '',
    partners: [
      createApplicationPartner([PersonRoleEnum.INSURED_PERSON], 1),
      createApplicationPartner([PersonRoleEnum.POLICY_HOLDER], 2),
      createApplicationPartner([PersonRoleEnum.PAYMENT_CONTRIBUTOR], 3)
    ]
  };
}

export function createApplicationPartner(
  roles: Array<PersonRoleEnum>,
  order: number,
  id?: string
): Partner {
  return {
    id: id || uuid(),
    order,
    birthDate: '',
    firstname: '',
    lastname: '',
    roles,
    salutation: undefined!,
    livingInGermany: undefined!,
    gender: undefined!,
    permanentResidence: {
      street: '',
      houseNumber: '',
      postalCode: '',
      city: ''
    },
    bankDetails: {
      bankName: undefined!,
      bicNumber: undefined!,
      iban: undefined!,
      hasPaymentAuthorization: null!
    },
    applicationInformation: {
      health: [],
      employer: '',
      employmentGroup: null!,
      hasInsolvency: null!,
      hasInsuranceDept: null!,
      hasLegalObligationToNotify: null!,
      hasUsedEmergencyTariff: null!,
      profession: null!
    }
  };
}
