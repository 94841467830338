// hooks
import { ChangeEvent, useEffect, useState } from 'react';

import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  RowClickedEvent,
  SortChangedEvent
} from 'ag-grid-community';
import classNames from 'classnames';
import { t } from 'i18next';

import TablePagination, { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';

import { Loader, LoaderType, Props, Table } from 'lkh-portal-ui-library';
import { Policy } from 'models/portal-generated';

import Filter from '../Filter';
import { NoResult } from 'components/NoResult/NoResult';
import { PageSize } from 'hooks/usePagination';
import { SortingOrder } from 'hooks/useSort';
import { TabType } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';
import { SecondLevelTabsProps } from 'pages/Contracts/types';

import styles from './Grid.module.scss';

type TableProps = {
  columnDef: Array<ColDef>;
  selectedTab: TabType;
  secondarySelectedTab: SecondLevelTabsProps;
  setSelectedTab?: (value: TabType) => void;
};

export const Grid = ({ columnDef, secondarySelectedTab, setSelectedTab }: TableProps) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { loading, contracts, pagination, sorting, total } = useContractPageContext();

  useEffect(() => {
    if (gridApi) {
      if (loading && gridApi) {
        //Fix implemented based on github issue https://github.com/ag-grid/ag-grid/issues/3849
        setTimeout(() => {
          gridApi.showLoadingOverlay();
        });
      }
      if (!loading && contracts.length === 0) {
        gridApi.showNoRowsOverlay();
      }
      if (!loading && contracts.length > 0) {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi, pagination]);

  const handleGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const handleSortChange = (e: SortChangedEvent) => {
    const sortingColumn = e.columnApi.getColumns()?.find((col) => col.isSorting());

    const resolvedOrder: SortingOrder | null = (() => {
      const agSortValue = sortingColumn?.getSort();

      if (agSortValue === 'asc') return SortingOrder.ASCENDING;

      return SortingOrder.DESCENDING;
    })();

    if (sortingColumn) {
      sorting?.set(sortingColumn.getColId(), resolvedOrder);
    } else {
      sorting?.reset();
    }
  };

  const handleRowClick = (params: RowClickedEvent<Policy>) => {
    const { id } = params.data || {};
    window.open(`/360/vertrag/${id}`);
  };
  const handlePageChange = (page: number) => {
    pagination.goTo(page);
  };

  const renderLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) =>
    `${paginationInfo.from}-${paginationInfo.to} ${t('common:pagination.of')} ${
      paginationInfo.count
    }`;

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    pagination.sizeTo(rowsPerPage as PageSize);
  };

  return (
    <div className={styles.results}>
      <Filter secondarySelectedTab={secondarySelectedTab} setSelectedTab={setSelectedTab} />
      <div className={classNames('table-override')}>
        <Table
          total={total}
          rowHeight={50}
          columnDefs={columnDef}
          suppressCellFocus
          suppressRowClickSelection
          rowData={contracts}
          defaultColDef={{
            sortable: false,
            comparator: () => 0,
            suppressMovable: true,
            suppressSizeToFit: false
          }}
          getRowClass={(params: RowClassParams) => {
            const rowIndex = params.node.rowIndex || 0;
            const commonClasses = 'text-sm text-text-80 tracking-wide';

            if (rowIndex % 2 === 0) {
              return `${commonClasses} bg-white-100`;
            }
            return `${commonClasses} bg-surface-60`;
          }}
          tooltipShowDelay={0}
          onSortChanged={(e) => handleSortChange(e)}
          onGridReady={(e) => handleGridReady(e)}
          onRowClicked={handleRowClick}
          loadingOverlayComponent={Loader}
          loadingOverlayComponentParams={{
            type: LoaderType.Circular,
            color: Props.BaseColors.Primary.Base
          }}
          noRowsOverlayComponent={NoResult}
          noRowsOverlayComponentParams={{
            warningMessage: 'Wir konnten keine entsprechenden Informationen finden',
            warningDescription:
              'Bitte versuchen Sie es erneut, indem Sie die Filteroptionen anpassen.'
          }}
        />
      </div>
      <TablePagination
        component="div"
        count={total}
        page={pagination.page}
        onPageChange={(_e, page) => handlePageChange(page)}
        rowsPerPage={pagination.size as number}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t<string>('common:pagination.rowsOnPage')}
        labelDisplayedRows={renderLabelDisplayedRows}
        slotProps={{ select: { MenuProps: { disableScrollLock: true } } }}
      />
    </div>
  );
};
