import { useTranslation } from 'react-i18next';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';

import { Complete } from './Complete';
import { Dropzone } from './Dropzone';
import { Uploader } from './Uploader';
import { UploadContextProvider, UploadStage, useUploadContext } from 'contexts/UploadContext';

const UploadDocumentsContent = () => {
  const { t } = useTranslation('view360', { keyPrefix: 'upload' });
  const { open, close, stage } = useUploadContext();

  return (
    <>
      <div className="text-right">
        <Button onClick={open} variant="outlined">
          <FileUploadOutlinedIcon className="mr-[8px]" />
          {t('dropzone.title')}
        </Button>
      </div>
      <Dialog
        disableScrollLock
        fullWidth
        maxWidth="md"
        open={stage !== UploadStage.Closed}
        onClose={close}
      >
        {stage === UploadStage.Browse && <Dropzone />}
        {stage === UploadStage.Upload && <Uploader />}
        {stage === UploadStage.Complete && <Complete />}
      </Dialog>
    </>
  );
};

export const UploadDocuments = ({ id }: { id: string }) => {
  const { t } = useTranslation('view360');

  return (
    <UploadContextProvider
      validationFn={(files) => {
        const errors = new Map<string, string>();
        if (files.length === 0) {
          return {
            hasError: true,
            errorMessage: t('upload.errors.noFiles')
          };
        }
        // validate intent for each file separately
        // TODO - switch to id instead of name. There could be multiple files with the same name
        files.forEach((file) => {
          if (!file.intent && file.name) {
            errors.set(file.name, t('upload.errors.noIntent'));
          }
        });

        return {
          hasError: errors.size > 0,
          errorMessage: t('upload.errors.noIntent'),
          errors
        };
      }}
      uploadUrl={`/api/policy/${id}/uploadDocument`}
    >
      <UploadDocumentsContent />
    </UploadContextProvider>
  );
};
