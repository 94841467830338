import { useState } from 'react';

import { ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { CircularProgress, MenuItem } from '@mui/material';

import { getCSVExport } from '../Grid/hooks/useContracts';
import { CsvIcon } from './CSVIcon';
import { useContractPageContext } from 'pages/Contracts/context';
import { downloadCsv } from 'utilities/fetchDocument';

type DownloadCsvProps = {
  columnDef: Array<ColDef>;
  setIsLoading: (loading: boolean) => void;
};

export function DownloadCsv(props: DownloadCsvProps) {
  const { filters, sorting } = useContractPageContext();
  const { t } = useTranslation('contract');

  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    setLoading(true);
    props.setIsLoading(true);

    try {
      const data = await getCSVExport(filters, sorting);
      downloadCsv(data, 'verträge.csv');
    } catch (error) {
      const errorMsg = t('tab.exportCSVError');
      toast.error(errorMsg);
    } finally {
      setLoading(false);
      props.setIsLoading(false);
    }
  };

  return (
    <MenuItem onClick={handleDownload} disabled={loading}>
      <div className="flex">
        <span className="flex items-center" style={{ marginRight: '18px' }}>
          {loading ? <CircularProgress color="inherit" size={16} /> : <CsvIcon />}
        </span>
        <span className="text-text-80 text-[14px] flex items-center">{t('viaCSV')}</span>
      </div>
    </MenuItem>
  );
}
