import sortBy from 'lodash/sortBy';

import { DropdownOption } from 'lkh-portal-ui-library';
import {
  FederalStateEnum,
  InsuranceCompanyEnum,
  NeedSituationEnum,
  WorkingDoctorRelationshipEnum
} from 'models/extension-generated';

import i18n from '../services/translation';

import { FormType, IntentEnum } from './portal-generated';
import { DropdownOption as DropdownOptionPortalGenerated } from 'pages/Contracts/types';

export const federalRepublicOptions: Array<DropdownOption> = [
  {
    value: FederalStateEnum.BADEN_WURTTEMBERG,
    label: i18n.t('FederalStateEnum.BADEN_WURTTEMBERG', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.BAYERN,
    label: i18n.t('FederalStateEnum.BAYERN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.BERLIN,
    label: i18n.t('FederalStateEnum.BERLIN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.BRANDERBURG,
    label: i18n.t('FederalStateEnum.BRANDERBURG', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.BREMEN,
    label: i18n.t('FederalStateEnum.BREMEN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.HAMBURG,
    label: i18n.t('FederalStateEnum.HAMBURG', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.HESSEN,
    label: i18n.t('FederalStateEnum.HESSEN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.MECKLENBURG_VORPOMMERN,
    label: i18n.t('FederalStateEnum.MECKLENBURG_VORPOMMERN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.NIEDERSACHSEN,
    label: i18n.t('FederalStateEnum.NIEDERSACHSEN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.NORDHEIN_WESTFALEN,
    label: i18n.t('FederalStateEnum.NORDHEIN_WESTFALEN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.RHEINLAND_PFALZ,
    label: i18n.t('FederalStateEnum.RHEINLAND_PFALZ', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.SAARLAND,
    label: i18n.t('FederalStateEnum.SAARLAND', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.SACHSEN,
    label: i18n.t('FederalStateEnum.SACHSEN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.SACHSEN_ANHALT,
    label: i18n.t('FederalStateEnum.SACHSEN_ANHALT', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.SCHLESWIG_HOLSTEIN,
    label: i18n.t('FederalStateEnum.SCHLESWIG_HOLSTEIN', { ns: 'contractsEnums' })
  },
  {
    value: FederalStateEnum.THURINGEN,
    label: i18n.t('FederalStateEnum.THURINGEN', { ns: 'contractsEnums' })
  }
];

export const federalRepublicOptionsWithGeneric: Array<DropdownOption> = [
  {
    value: FederalStateEnum.BUND,
    label: i18n.t('FederalStateEnum.BUND', { ns: 'contractsEnums' })
  },
  ...federalRepublicOptions
];

export const vertriebTypeOptions: DropdownOptionPortalGenerated[] = [
  { label: i18n.t('formsPage:filter.advAssistance'), value: FormType.BERATUNGSHILFEN_KV }
];

export const lkhTypeOptions: DropdownOptionPortalGenerated[] = [
  {
    label: i18n.t('formsPage:filter.tariff'),
    value: FormType.TARIFE
  },
  {
    label: i18n.t('formsPage:filter.sample'),
    value: FormType.MUSTER
  },
  {
    label: i18n.t('formsPage:filter.requests'),
    value: FormType.ANTRAGE
  },
  {
    label: i18n.t('formsPage:filter.avb'),
    value: FormType.AVB
  },
  {
    label: i18n.t('formsPage:filter.combinedPacks'),
    value: FormType.KOMBIDRUCKSTUCKE
  },
  {
    label: i18n.t('formsPage:filter.others'),
    value: FormType.SONSTIGES
  },
  {
    label: i18n.t('formsPage:filter.overview'),
    value: FormType.UBERSICHT
  },
  {
    label: i18n.t('formsPage:filter.additionalRequests'),
    value: FormType.ZUSATZANTRAGE
  },
  {
    label: i18n.t('formsPage:filter.brochures'),
    value: FormType.PROSPEKTE
  },
  {
    label: i18n.t('formsPage:filter.otherGuides'),
    value: FormType.SONSTIGE_RICHTLINIEN
  },
  {
    label: i18n.t('formsPage:filter.selfInfo'),
    value: FormType.SELBSTAUSKUNFTE
  }
];

export const formsFilter2012Options: DropdownOptionPortalGenerated[] = [
  {
    label: i18n.t('formsPage:filter.documentsFrom2012'),
    value: 'false'
  },
  {
    label: i18n.t('formsPage:filter.documentsSince2012'),
    value: 'true'
  }
];

export const needSituationOptions: Array<DropdownOption> = [
  {
    value: 'VOLVERSICHERUNG_GROUP',
    label: i18n.t('NeedSituationEnum.VOLVERSICHERUNG_GROUP', { ns: 'contractsEnums' }),
    type: 'group'
  },
  {
    value: NeedSituationEnum.ANU_P,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.ANU_P}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.SU_P,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.SU_P}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.KANS,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.KANS}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.HMU_P,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.HMU_P}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.KHM,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.KHM}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.SU_S,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.SU_S}`, { ns: 'contractsEnums' })
  },
  {
    value: 'RESTKOSTENABSICHERUNG_GROUP',
    label: i18n.t('NeedSituationEnum.RESTKOSTENABSICHERUNG_GROUP', { ns: 'contractsEnums' }),
    type: 'group'
  },
  {
    value: NeedSituationEnum.BU_P,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.BU_P}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.BAU_P,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.BAU_P}`, { ns: 'contractsEnums' })
  },
  {
    value: NeedSituationEnum.KB,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.KB}`, { ns: 'contractsEnums' })
  },
  {
    value: 'ZUSATZVERSICHERUNG_GROUP',
    label: i18n.t('NeedSituationEnum.ZUSATZVERSICHERUNG_GROUP', { ns: 'contractsEnums' }),
    type: 'group'
  },
  {
    value: NeedSituationEnum.ZVGKV,
    label: i18n.t(`NeedSituationEnum.${NeedSituationEnum.ZVGKV}`, { ns: 'contractsEnums' })
  }
];

export const workingDoctorRelationshipOptions: Array<DropdownOption> = [
  {
    value: WorkingDoctorRelationshipEnum.EMPLOYED,
    label: i18n.t('WorkingDoctorRelationshipEnum.EMPLOYED', { ns: 'contractsEnums' })
  },
  {
    value: WorkingDoctorRelationshipEnum.SELF_EMPLOYED,
    label: i18n.t('WorkingDoctorRelationshipEnum.SELF_EMPLOYED', { ns: 'contractsEnums' })
  }
];

const InsuranceCompanies = [
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.CONTINENTALE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.CONTINENTALE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.SIGNAL}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.SIGNAL.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.CENTRAL}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.CENTRAL.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.LKH}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.LKH.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.DEUTSCHER_RING}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.DEUTSCHER_RING.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.DEBEKA}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.DEBEKA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.INTER}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.INTER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.ALLIANZ_PRIVATE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.ALLIANZ_PRIVATE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.MUNCHENER}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.MUNCHENER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.SUDDEUTSCHE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.SUDDEUTSCHE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.BARMENIA_A_G}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.BARMENIA_A_G.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.HALLESCHE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.HALLESCHE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.DKV}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.DKV.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.UNIVERSA}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.UNIVERSA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.LIGA}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.LIGA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.FREIE_ARZT_UND_MEDIZINKASSE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.FREIE_ARZT_UND_MEDIZINKASSE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.AXA}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.AXA.toString()
  },
  {
    label: i18n.t(
      `InsuranceCompanyEnum.${InsuranceCompanyEnum.KRANKENUNTERSTUTZUNGSKASSE_HANNOVER}`,
      { ns: 'contractsEnums' }
    ),
    value: InsuranceCompanyEnum.KRANKENUNTERSTUTZUNGSKASSE_HANNOVER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.VICTORIA}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.VICTORIA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.ST_MARTINUS}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.ST_MARTINUS.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.UNION}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.UNION.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.LVM}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.LVM.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.ARAG}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.ARAG.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.DUSSELDORFER}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.DUSSELDORFER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.R_PLUS_V}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.R_PLUS_V.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.HUK_COBURG}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.HUK_COBURG.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.CONCORDIA}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.CONCORDIA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.GOTHAER}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.GOTHAER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.NURNBERGER}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.NURNBERGER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.BBV}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.BBV.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.DEVK}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.DEVK.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.BAYERISCHE_BEAMTENKRANKENKASSE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.BAYERISCHE_BEAMTENKRANKENKASSE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.PROVINZIAL}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.PROVINZIAL.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.WURTTEMBERGISCHE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.WURTTEMBERGISCHE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.MECKLENBURGISCHE}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.MECKLENBURGISCHE.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.ALTE_OLDENBURGER}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.ALTE_OLDENBURGER.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.PAX}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.PAX.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.HANSEMERKUR}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.HANSEMERKUR.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.INTER_NEU}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.INTER_NEU.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.OTTONOVA}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.OTTONOVA.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.BARMENIA_AG}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.BARMENIA_AG.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.PBEAKK}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.PBEAKK.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.KVB}`, { ns: 'contractsEnums' }),
    value: InsuranceCompanyEnum.KVB.toString()
  },
  {
    label: i18n.t(`InsuranceCompanyEnum.${InsuranceCompanyEnum.GPV_PKV_VERBAND}`, {
      ns: 'contractsEnums'
    }),
    value: InsuranceCompanyEnum.GPV_PKV_VERBAND.toString()
  }
];

export const InsuranceCompanyOptions = sortBy(InsuranceCompanies, (company) =>
  company.label.toLocaleLowerCase()
);

export const IntentEnumOptions: Array<DropdownOption> = Object.values(IntentEnum).map(
  (value: IntentEnum) => ({
    value,
    label: i18n.t(`IntentEnum.${value}`, { ns: 'contractsEnums' })
  })
);
