import { forwardRef } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { AgentProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { formatPrice } from '../../../../utilities/string';
import { formatPercent, optionalValue } from '../../utils/formatters';
import { AccordionHandle, SectionAccordion } from '../SectionAccordion';
import { SimpleTable } from 'components/SimpleTable';

export const Agent = forwardRef<AccordionHandle, AgentProps>(
  ({ salutation, academicTitle, firstName, lastName, agentId, provisions }, ref) => {
    const { t } = useTranslation('view360');

    return (
      <SectionAccordion
        header={<Typography variant="bodyMDBold">{t(`agent.title`)}</Typography>}
        ref={ref}
      >
        <div className="row reset-container m-t-24 m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.personalData')}
              rows={[
                { label: t(`person.attributes.salutation`), value: optionalValue(salutation) },
                {
                  label: t(`person.attributes.academicTitle`),
                  value: optionalValue(academicTitle)
                },
                { label: t(`person.attributes.firstName`), value: optionalValue(firstName) },
                { label: t(`person.attributes.lastName`), value: optionalValue(lastName) }
              ]}
            />
          </div>
          <div className="col-6">
            <SimpleTable
              title={t('agent.agentData')}
              rows={[{ label: t(`agent.attributes.agentId`), value: optionalValue(agentId) }]}
            />
          </div>
        </div>

        <div className="row reset-container m-b-24">
          {(provisions || []).map((provision, index) => (
            <div className="col-6" key={provision.tariffType}>
              <SimpleTable
                title={`${t('agent.provisionData')} ${index + 1}*`}
                rows={[
                  {
                    label: t('agent.attributes.tariffType'),
                    value: optionalValue(provision.tariffType)
                  },
                  {
                    label: t('agent.attributes.comissionReferenceDate'),
                    value: optionalValue(provision.comissionReferenceDate, formatGermanDate)
                  },
                  {
                    label: t('agent.attributes.paymentFrequency'),
                    value: optionalValue(provision.paymentFrequency)
                  },
                  {
                    label: t('agent.attributes.rate'),
                    value: optionalValue(provision.rate, formatPercent)
                  },
                  {
                    label: t('agent.attributes.sum'),
                    value: optionalValue(provision.amount, formatPrice)
                  }
                ]}
              />
            </div>
          ))}
          {provisions && provisions.length > 0 && (
            <Typography variant="bodySMRegular" className="px-[20px] py-m text-text-100">
              <Trans i18nKey="agent.note" ns="view360" components={{ bold: <strong /> }} />
            </Typography>
          )}
        </div>
      </SectionAccordion>
    );
  }
);
