import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { Application, ApplicationStatusEnum } from 'models/extension-generated';

import { ActionMenu } from 'pages/Contract360Page/components/ActionMenu';

export type TableActionMenuProps = ICellRendererParams<Application> & {
  onDeleteApplicationClick: (id: string) => void;
  onDuplicateApplicationClick: (id: string) => void;
  onSignApplicationClick: (id: string) => void;
  onMenuClose: () => void;
};

export const TableActionMenu = ({
  data: applicationData,
  onDeleteApplicationClick,
  onDuplicateApplicationClick,
  onSignApplicationClick,
  onMenuClose
}: TableActionMenuProps) => {
  if (!applicationData) return <></>;
  const { t } = useTranslation('dashboard');
  const handleEdit = () => {
    window.open(`/wizard?id=${applicationData?.id}`);
  };

  const duplicateApplication = () => {
    onDuplicateApplicationClick(applicationData?.id || '');
  };

  const signApplication = () => {
    onSignApplicationClick(applicationData?.id || '');
  };

  const deleteApplication = () => {
    onDeleteApplicationClick(applicationData?.id || '');
  };

  return (
    <ActionMenu
      items={
        <div>
          <MenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <ModeEditOutlineOutlinedIcon className="fill-text-80" />
            </ListItemIcon>
            <ListItemText className="text-text-80">{t('actions.edit')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => duplicateApplication()}>
            <ListItemIcon>
              <ContentCopyOutlinedIcon className="fill-text-80" />
            </ListItemIcon>
            <ListItemText className="text-text-80">{t('actions.duplicate')}</ListItemText>
          </MenuItem>
          {applicationData.status === ApplicationStatusEnum.APPLICATION_PUBLISHED && (
            <MenuItem onClick={() => signApplication()}>
              <ListItemIcon>
                <BookmarkAddedOutlinedIcon className="fill-text-80" />
              </ListItemIcon>
              <ListItemText className="text-text-80">
                {t('actions.markProcessAsCompleted')}
              </ListItemText>
            </MenuItem>
          )}
          {applicationData.status !== ApplicationStatusEnum.APPLICATION_PUBLISHED &&
            applicationData.status !== ApplicationStatusEnum.APPLICATION_CREATED && [
              <Divider key="table-action-menu-divider" className="m-[0px]" />,
              <MenuItem
                key="table-action-menu-delete-time"
                className="hover:bg-danger-10"
                onClick={() => deleteApplication()}
              >
                <ListItemIcon>
                  <DeleteForeverOutlinedIcon className="fill-[#E12D39]" />
                </ListItemIcon>
                <ListItemText className="text-[#E12D39]">{t('actions.delete')}</ListItemText>
              </MenuItem>
            ]}
        </div>
      }
      onMenuClose={onMenuClose}
    />
  );
};
