import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import { AlertIcon, Icon } from 'lkh-portal-ui-library';

import styles from './NoResult.module.scss';

type NoResultProps = {
  className?: string;
  warningMessage: string;
  warningDescription: string;
};

export const NoResult = ({ className, warningMessage, warningDescription }: NoResultProps) => {
  return (
    <div className={classNames(styles.noResult, className)}>
      <Icon
        color="lkh-color-primary-80"
        className={classNames(styles.warningIcon, 'm-b-16', 'text-warning-700')}
        icon={<AlertIcon />}
      />
      <Typography variant="bodyMDRegular" className="mb-[8px]">
        {warningMessage}
      </Typography>
      <Typography variant="bodySMRegular" className="text-neutral-800">
        {warningDescription}
      </Typography>
    </div>
  );
};
