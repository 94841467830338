import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Loader, LoaderType } from 'lkh-portal-ui-library';

import { useUploadContext } from 'contexts/UploadContext';

export const Uploader = () => {
  const { t } = useTranslation('view360', { keyPrefix: 'upload' });
  const { completed, files, close } = useUploadContext();

  return (
    <div className="p-[24px]">
      <div className="mb-[12px] flex flex-row justify-between items-center">
        <Typography variant="bodyMDBold">{t('oneMoment')}</Typography>
        <div onClick={close} className="cursor-pointer">
          <CloseIcon fontSize="inherit" />
        </div>
      </div>

      <div className="row reset-container mb-[24px]">
        <div className="col">
          <Typography className="text-text-60">{t('uploading.description')}</Typography>
        </div>
      </div>

      <div className="row reset-container">
        <div className="col flex justify-between items-center">
          <div>
            <Loader type={LoaderType.Circular} className="d-inline-block mr-[16px]" />
            <span className="text-sm text-text-80">
              {`${completed}/${files.length} ${t('uploading.counter')}`}
            </span>
          </div>
          <Button className="py-[11px]" size="large" onClick={close}>
            {t('actions.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};
