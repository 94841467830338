import { useQuery } from '@tanstack/react-query';

import { log } from 'lkh-portal-ui-library';
import { PoliciesResponse, PolicyService, SearchOperationEnum } from 'models/portal-generated';

import { TabType } from '../GridGroup';

const fetchReminders = (): Promise<PoliciesResponse> => {
  return PolicyService.getPolicies({
    requestBody: {
      page: 0,
      size: 10,
      searchCriteria: [
        {
          criteria: [
            {
              key: 'hasReminder',
              operation: SearchOperationEnum.EQUALS,
              value: true
            }
          ]
        }
      ]
    }
  });
};
const fetchContracts = (): Promise<PoliciesResponse> => {
  return PolicyService.getPolicies({
    requestBody: {
      page: 0,
      size: 10
    }
  });
};

type CounterType = {
  [TabType.Contracts]: number;
  [TabType.Reminders]: number;
};

export const useFetchCount = () => {
  const fetchCounts = async (): Promise<CounterType> => {
    try {
      const [allContracts, reminders] = await Promise.all([fetchContracts(), fetchReminders()]);

      return {
        contracts: allContracts.total,
        reminders: reminders.total
      };
    } catch (error) {
      log.error(error);
      return Promise.resolve({
        contracts: 0,
        reminders: 0
      });
    }
  };

  return useQuery<CounterType>({
    queryKey: ['count'],
    queryFn: fetchCounts
  });
};
