import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axios } from 'services/axios';

import { IntentEnum } from 'models/portal-generated';

export interface FileDetail extends File {
  binary?: Blob;
  intent?: IntentEnum;
  comment?: string;
}

export const enum UploadStage {
  Closed,
  Browse,
  Upload,
  Complete
}

export type Errors = Map<string, string>;

/**
 * Contextual data available anywhere under the provider
 */
export type UploadContextType = {
  files: Array<FileDetail>;
  setFiles: Dispatch<SetStateAction<Array<FileDetail>>>;
  error?: string;
  setError: Dispatch<SetStateAction<string | undefined>>;
  errors: Errors;
  stage: UploadStage;
  setStage: Dispatch<SetStateAction<UploadStage>>;
  completed: number;
  setCompleted: Dispatch<SetStateAction<number>>;
  setIntent: (fileName: string, intent: IntentEnum) => void;
  open: () => void;
  close: () => void;
  upload: () => void;
};

export const UploadContext = createContext<UploadContextType | null>(null);

export type UploadContextProviderType = {
  uploadUrl: string;
  onDone?: () => void;
  validationFn?: (files: Array<FileDetail>) => {
    hasError: boolean;
    errorMessage: string;
    errors?: Errors;
  };
};

export function UploadContextProvider(props: UploadContextProviderType & PropsWithChildren) {
  const { t } = useTranslation('view360', { keyPrefix: 'upload' });
  const [stage, setStage] = useState<UploadStage>(UploadStage.Closed);
  const [files, setFiles] = useState<Array<FileDetail>>([]);
  const [error, setError] = useState<string>();
  const [errors, setErrors] = useState<Errors>(new Map());
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    if (files.length > 0 && files.length === completed) {
      setStage(UploadStage.Complete);
    }
  }, [completed, files]);

  const setIntent = (fileName: string, intent: IntentEnum) => {
    setFiles((files) => {
      return files.map((file) => {
        const fileCopy: FileDetail = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified
        });
        if (file.name === fileName) {
          fileCopy.intent = intent;
        } else {
          fileCopy.intent = file.intent;
        }

        fileCopy.comment = file.comment;
        fileCopy.binary = file.binary;

        return fileCopy;
      });
    });
  };

  const open = () => {
    setStage(UploadStage.Browse);
  };

  const close = () => {
    setFiles([]);
    setCompleted(0);
    setError(undefined);
    setStage(UploadStage.Closed);
  };

  const upload = async () => {
    if (validate()) {
      setStage(UploadStage.Upload);

      const uploadSingleDocument = async (file: FileDetail) => {
        // weird but this is only way how BE accepts the data
        // its deviation from yaml, it does not work via swagger too
        // but this is how postman sends the metadata (as a file as application/json)

        const formData = new FormData();

        formData.append('files', file);
        formData.append(
          'metadata',
          new Blob([JSON.stringify([{ intent: file.intent, comment: file.comment }])], {
            type: 'application/json'
          })
        );
        return axios.post(props.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        });
      };
      try {
        await Promise.all(
          files.map(async (file) => {
            await uploadSingleDocument(file);
            setCompleted((n) => n + 1);
          })
        );
      } catch (error) {
        if (error instanceof AxiosError) {
          let errorMsg;

          switch (error.response?.status) {
            case 417:
              errorMsg = t('errors.virusDetected');
              break;
            case 422:
              errorMsg = t('errors.encryptedOrUnsupported');
              break;
            default:
              errorMsg = t('toastMessages.somethingWentWrong', { ns: 'common' });
          }
          toast.error(errorMsg);
        }
      } finally {
        setStage(UploadStage.Browse);
      }
      props.onDone?.();
    }
  };

  const validate = (): boolean => {
    const { hasError, errorMessage, errors } = props.validationFn?.(files) || {};
    const noFiles = files.length === 0;

    setError(() => {
      if (noFiles) {
        return t('errors.noFiles');
      }

      if (hasError) {
        return errorMessage;
      }

      return undefined;
    });
    if (errors?.size) {
      setError(t('errors.noIntent'));
      setErrors(errors);
    }

    return !(hasError || noFiles);
  };

  const contextValue = useMemo(
    () => ({
      files,
      error,
      errors,
      setFiles,
      setError,
      stage,
      setStage,
      completed,
      setCompleted,
      open,
      close,
      setIntent,
      upload
    }),
    [files, error, stage, completed]
  );

  return <UploadContext.Provider value={contextValue}>{props.children}</UploadContext.Provider>;
}

export function useUploadContext(): UploadContextType {
  const context = useContext(UploadContext);

  if (!context) {
    throw new Error(
      'To use "useUploadContext" some of the parent components must be within its Provider'
    );
  }

  return context;
}
