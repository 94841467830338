import { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AddCommentIcon from '@mui/icons-material/AddComment';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Autocomplete, IconButton, TextField } from '@mui/material';

import {
  CheckCircleIcon,
  DarkTooltip,
  DropdownOption,
  Icon,
  Props,
  Textarea
} from 'lkh-portal-ui-library';
import { IntentEnumOptions } from 'models';
import { IntentEnum } from 'models/portal-generated';

import { FileDetail } from 'contexts/UploadContext';

import styles from './UploadDocuments.module.scss';

type DocumentProps = {
  file: FileDetail;
  showError?: boolean;
  showIntent?: boolean;
  onDelete?: (file: FileDetail) => void;
  onIntentSelection?: (fileName: string, intent: IntentEnum) => void;
};

export const Document = ({
  file,
  showError,
  showIntent,
  onDelete,
  onIntentSelection
}: DocumentProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'upload' });
  const [showCommentBox, setShowCommentBox] = useState(false);
  const intentValue = file.intent
    ? IntentEnumOptions.find((option) => option.value === file.intent)
    : null;

  function formatBytes(bytes: number, decimals: number) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  const categoryOptions = useMemo<Array<DropdownOption>>(() => IntentEnumOptions, []);

  function handleIntentChange(opts?: string | null | undefined) {
    const intent = categoryOptions.find((option) => option.value === opts)?.value as IntentEnum;
    onIntentSelection?.(file.name, intent);
  }

  const errorIntent = showError && !file.intent;

  const handleToggleUpdate = () => {
    setShowCommentBox((prev) => {
      const isVisible = !prev;
      if (!isVisible) {
        file.comment = '';
      }
      return isVisible;
    });
  };

  function handleCommentUpdate(text: string) {
    file.comment = text;
  }

  return (
    <>
      <div
        className={classNames(
          styles.fileWrapper,
          'row p-[16px] mb-[16px] flex flex-row items-center'
        )}
      >
        <div className="basis-1/2 flex flex-row items-center justify-between">
          <div className="basis-1/12 flex items-center">
            <Icon
              size="xl"
              icon={<CheckCircleIcon />}
              color={intentValue ? Props.StatusColors.Success.Base : Props.NeutralColors.S300}
            />
          </div>
          <div className="px-[0px] basis-9/12 flex items-center">
            <DarkTooltip title={file.name}>
              <span className="text-sm text-text-80 h-[18px] leading-5 ml-[8px]">
                {file.name.length > 18 ? file.name.slice(0, 18).concat('...') : file.name}
              </span>
            </DarkTooltip>
          </div>
          <div className="basis-2 px-[8px] whitespace-nowrap">
            <span className="text-sm text-text-40">{formatBytes(file.size, 0)}</span>
          </div>
        </div>
        <div className="basis-1/2">
          <div className="flex flex-row justify-end items-center w-full">
            <div className="">
              {showIntent && (
                <Autocomplete
                  fullWidth
                  value={intentValue}
                  options={categoryOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errorIntent}
                      label={t('files.intent')}
                      className="w-[271px]"
                    />
                  )}
                  onChange={(_event, value) => {
                    handleIntentChange(value?.value as string);
                  }}
                />
              )}
            </div>

            <div>
              <IconButton aria-label="comment" onClick={handleToggleUpdate}>
                {showCommentBox ? <AddCommentIcon /> : <AddCommentOutlinedIcon />}
              </IconButton>
            </div>
            <div>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  onDelete?.(file);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      {showCommentBox && (
        <div className="row my-[16px]">
          <div className="">
            <Textarea
              placeholder={t('files.commentPlaceholder')}
              rows={3}
              maxLength={100}
              onChange={handleCommentUpdate}
            />
          </div>
        </div>
      )}
    </>
  );
};
