import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { cols } from '../models';
import { DocumentService } from 'models/portal-generated';

import i18n from 'services/translation';

import { DownloadCsv } from '../../DownloadCsv';
import { TabMapping } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';
import { SecondLevelTabsProps } from 'pages/Contracts/types';
import { downloadGDVZipFiles } from 'utilities/fetchDocument';

type TabsProps = {
  selectedTab: SecondLevelTabsProps;
  setSelectedTab: (tab: SecondLevelTabsProps) => void;
};

export const SecondLevelTabs = ({ selectedTab, setSelectedTab }: TabsProps) => {
  const { filters, fetchContractsFn, loading, count } = useContractPageContext();
  const { t } = useTranslation('contract');

  const { contract, insuredPerson, specialSearch } = count;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gdvIsLoading, setGdvIsLoading] = useState<boolean>(false);
  const [isOpenGDVModal, setIsOpenGDVModal] = useState<boolean>(false);

  const anchor = useRef(null);

  const handleClick = () => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(anchor.current);
    }
  };

  const handleTabChange = (_event: React.MouseEvent<HTMLElement>, value: string | number) => {
    const selectedTab = TabMapping[value];
    if (selectedTab) {
      setSelectedTab(selectedTab);
    }
  };

  const downloadGdvFile = async () => {
    setAnchorEl(anchor.current);
    setGdvIsLoading(true);
    try {
      const response = await DocumentService.downloadGdvFile();
      downloadGDVZipFiles(response);
    } catch (error) {
      setIsOpenGDVModal(true);
    } finally {
      setGdvIsLoading(false);
    }
  };

  const handleCloseGDVModal = () => {
    setIsOpenGDVModal(false);
  };

  return (
    <div className="w-[100%] flex flex-col items-start justify-between space-y-5 l:flex-row l:items-center l:space-y-0">
      <div className="flex items-center space-x-3">
        <ToggleButtonGroup
          exclusive
          size="small"
          className="h-[32px]!"
          value={selectedTab}
          onChange={handleTabChange}
        >
          <ToggleButton
            value={SecondLevelTabsProps.Contract}
            aria-label={SecondLevelTabsProps.Contract}
          >
            {contract > 0 && <p className="m-0">{`(${contract})`}&nbsp;</p>}
            {i18n.t<string>('tab.contract', { ns: 'contract' })}
          </ToggleButton>
          <ToggleButton
            value={SecondLevelTabsProps.InsuredPerson}
            aria-label={SecondLevelTabsProps.InsuredPerson}
          >
            {insuredPerson > 0 && <p className="m-0">{`(${insuredPerson})`}&nbsp;</p>}
            {i18n.t<string>('tab.insuredPerson', { ns: 'contract' })}
          </ToggleButton>
          <ToggleButton
            value={SecondLevelTabsProps.SpecialSearch}
            aria-label={SecondLevelTabsProps.SpecialSearch}
          >
            {specialSearch > 0 && <p className="m-0">{`(${specialSearch})`}&nbsp;</p>}
            {i18n.t<string>('tab.specialSearch', { ns: 'contract' })}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {/* Action buttons section */}
      <div className="flex items-center space-x-3">
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            filters.reset();
          }}
          disabled={filters.active.length === 0}
        >
          {t('resetFilter')}
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="outlined"
          size="small"
          onClick={fetchContractsFn}
        >
          {t('refetchContract')}
        </Button>
        <ClickAwayListener
          onClickAway={() => {
            if (!isLoading && !gdvIsLoading) {
              setAnchorEl(null);
            }
          }}
        >
          <Button
            ref={anchor}
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleClick}
            aria-controls={anchorEl ? 'simple-menu' : undefined}
          >
            {t('export')} <ArrowDropDownIcon />
          </Button>
        </ClickAwayListener>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">
          <Paper
            elevation={16}
            sx={{
              paddingTop: '8px',
              paddingBottom: '8px',
              width: '180px',
              marginTop: '4px'
            }}
          >
            <DownloadCsv columnDef={cols()} setIsLoading={setIsLoading} />
            <MenuItem onClick={downloadGdvFile} disabled={gdvIsLoading}>
              <div className="flex">
                <span
                  className="flex items-center"
                  style={{ marginRight: gdvIsLoading ? '26px' : '18px' }}
                >
                  {gdvIsLoading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <FolderZipOutlinedIcon />
                  )}
                </span>
                <Typography variant="bodyMDRegular" className="text-text-80 items-center">
                  {t('viaGDV')}
                </Typography>
              </div>
            </MenuItem>
          </Paper>
        </Popper>

        <Dialog
          disableScrollLock
          fullWidth
          maxWidth="sm"
          open={isOpenGDVModal}
          onClose={handleCloseGDVModal}
        >
          <div className="py-[24px] px-[32px]">
            <div className="inline-flex gap-8">
              <div className="pl-[16px]">
                <Typography variant="bodyLGBold" className="flex text-text-100">
                  {t('data_request')}
                </Typography>
                <Typography
                  variant="bodyMDRegular"
                  className="flex text-text-80 text-sm font-normal mt-[4px]"
                >
                  {t('contact_service_center_start')}&nbsp;
                  <Typography
                    variant="bodyMDRegularLink"
                    component={'a'}
                    href="mailto:vertriebsservice@lkh.de?subject=LKH Vermittlerportal - Vertriebsverwaltung - Antrag auf GDV-Data Export"
                  >
                    {t('contract:contact_service_center_link')}
                  </Typography>
                  &nbsp;{t('contact_service_center_end')}
                </Typography>
              </div>
            </div>
            <div className="flex justify-end gap-[16px] mt-[32px]">
              <Button size="large" onClick={handleCloseGDVModal}>
                {t('close')}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
