import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import sortBy from 'lodash/sortBy';
import moment from 'moment';

import { ColumnIds } from '../components/Table/models';
import {
  Application,
  ApplicationService,
  ApplicationStatusEnum,
  CancelablePromise
} from 'models/extension-generated';

import { formatDate } from '../../../utilities/dates';
import { applicationFiltersReducer, ApplicationGroups } from './ApplicationFilterReducer';
import {
  ApplicationFilterActions,
  ApplicationsState,
  DeleteApplicationPayload,
  TabType
} from './types';
import { SortingOrder } from 'hooks/useSort';

export type DashboardContextValue = {
  state: ApplicationsState;
  loading: boolean;
  error: boolean;
  selectedTab: TabType;
  actions: {
    setActiveTab: (activeTab: TabType) => void;
    setCreationDateRange: ({ from, to }: { from?: string; to?: string }) => void;
    setSortBy: (payload: { columnId: ColumnIds; order: SortingOrder }) => void;
    resetFilters: () => void;
    deleteApplication: (payload: DeleteApplicationPayload) => void;
    setStatus: (status: ApplicationStatusEnum[] | undefined) => void;
    setId: (id: string) => void;
    setFirstName: (firstname: string) => void;
    setLastName: (lastname: string) => void;
    confirmDialog: () => void;
  };
};

export const DashboardContext = createContext<DashboardContextValue | null>(null);

type DashboardContextProviderProps = { children: ReactNode };

export const DashboardContextProvider = ({ children }: DashboardContextProviderProps) => {
  const today = moment();
  const initialState: ApplicationsState = {
    applications: [],
    filters: {
      firstname: '',
      lastname: '',
      id: '',
      statuses: ApplicationGroups.OpenApplications,
      creationDateUntil: formatDate(today.toISOString()),
      creationDateFrom: formatDate(today.subtract(7, 'days').toISOString())
    },
    selectedTab: TabType.OpenApplications
  };

  const [state, dispatch] = useReducer(applicationFiltersReducer, initialState);
  const [isDialogConfirmed, setIsDialogConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { creationDateFrom, creationDateUntil, statuses, firstname, lastname, id } = state.filters;
  const statusesString = statuses?.join(',');
  const request = useRef<{ promise: CancelablePromise<Array<Application>> | null }>({
    promise: null
  });

  const cancelCurrentRequest = () => {
    request.current?.promise?.cancel();
  };

  const fetchApplicationsFn = async () => {
    setLoading(true);
    cancelCurrentRequest();
    try {
      request.current.promise = ApplicationService.getApplications({
        requestBody: {
          firstname: state.filters.firstname ? '%' + state.filters.firstname + '*' : undefined,
          lastname: state.filters.lastname ? '%' + state.filters.lastname + '*' : undefined,
          id: state.filters.id ? '%' + state.filters.id + '*' : undefined,
          statuses:
            state.selectedTab === TabType.OpenApplications
              ? ApplicationGroups.OpenApplications
              : ApplicationGroups.SubmittedApplications,
          creationDateUntil: state.filters.creationDateUntil,
          creationDateFrom: state.filters.creationDateFrom
        }
      });
      const applications = await request.current.promise;
      const sorted = sortBy(applications, (application) => application.creationDate).reverse();

      dispatch({ type: ApplicationFilterActions.SET_APPLICATIONS, payload: sorted });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
      setIsDialogConfirmed(false);
    }
  };

  // Fetch new applications when filters change
  useEffect(() => {
    fetchApplicationsFn();
  }, [creationDateFrom, creationDateUntil, statusesString, firstname, lastname, id]);

  // Refech application data when user confirms dialog
  useEffect(() => {
    if (isDialogConfirmed) {
      fetchApplicationsFn();
    }
  }, [isDialogConfirmed]);

  const confirmDialog = () => {
    setIsDialogConfirmed(true);
  };

  const setCreationDateRange = ({ from, to }: { from?: string; to?: string }) => {
    dispatch({ type: ApplicationFilterActions.SET_DATE_RANGE, payload: { from, to } });
  };
  const resetFilters = () => {
    dispatch({ type: ApplicationFilterActions.RESET_FILTER, payload: null });
  };
  const setActiveTab = (activeTab: TabType) => {
    dispatch({ type: ApplicationFilterActions.SET_ACTIVE_TAB, payload: activeTab });
  };
  const setSortBy = (payload: { columnId: ColumnIds; order: SortingOrder }) => {
    dispatch({ type: ApplicationFilterActions.SET_SORT_BY, payload });
  };
  const deleteApplication = (payload: DeleteApplicationPayload) => {
    dispatch({ type: ApplicationFilterActions.DELETE_APPLICATION, payload });
  };
  const setStatus = (status: ApplicationStatusEnum[] | undefined) => {
    dispatch({ type: ApplicationFilterActions.SET_STATUS, payload: status });
  };
  const setFirstName = (name: string) => {
    dispatch({ type: ApplicationFilterActions.SET_FIRSTNAME, payload: name });
  };
  const setLastName = (name: string) => {
    dispatch({ type: ApplicationFilterActions.SET_LASTNAME, payload: name });
  };
  const setId = (id: string) => {
    dispatch({ type: ApplicationFilterActions.SET_ID, payload: id });
  };

  const ctx: DashboardContextValue = useMemo(() => {
    return {
      state,
      loading,
      error,
      selectedTab: state.selectedTab,
      actions: {
        setCreationDateRange,
        resetFilters,
        setActiveTab,
        setSortBy,
        deleteApplication,
        setStatus,
        confirmDialog,
        setFirstName,
        setLastName,
        setId
      }
    };
  }, [state, loading, error]);

  return <DashboardContext.Provider value={ctx}>{children}</DashboardContext.Provider>;
};

export const useDashboardPageContext = (): DashboardContextValue => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardPageContext was used outside of its Provider');
  }

  return context;
};
