/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Application } from '../models/Application';
import type { ApplicationFilters } from '../models/ApplicationFilters';
import type { CalculationResponse } from '../models/CalculationResponse';
import type { IdResponse } from '../models/IdResponse';
import type { InSignResponse } from '../models/InSignResponse';
import type { InSignStatus } from '../models/InSignStatus';
import type { ValidationResponse } from '../models/ValidationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicationService {

    /**
     * Create new public application
     * @returns IdResponse Application successfully created
     * @throws ApiError
     */
    public static createPublicApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Calculate prices for the public application
     * @returns CalculationResponse Calculation successfully executed
     * @throws ApiError
     */
    public static calculatePublicApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<CalculationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application/calculate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create new application
     * @returns IdResponse Application successfully created
     * @throws ApiError
     */
    public static createApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Submit public application
     * @returns IdResponse Application successfully submitted.
     * @throws ApiError
     */
    public static submitPublicApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application/submit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Submit new application
     * @returns IdResponse Application successfully submitted.
     * @throws ApiError
     */
    public static submitApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/submit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Sign the application digitally
     * @returns InSignResponse Signing session created.
     * @throws ApiError
     */
    public static signApplicationDigitally({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<InSignResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/{id}/sign/digital',
            path: {
                'id': id,
            },
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
                512: `Insign service unavailable`,
            },
        });
    }

    /**
     * Create an offer
     * @returns string Offer successfully received.
     * @throws ApiError
     */
    public static downloadOffer({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/offer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create an offer
     * @returns string Offer successfully received.
     * @throws ApiError
     */
    public static downloadPublicOffer({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application/offer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download accompany documents
     * @returns string Accompany documents downloaded
     * @throws ApiError
     */
    public static downloadAccompanyDocuments({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application/accompanyDocs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get all applications for agent
     * @returns Application Applications successfully retrieved
     * @throws ApiError
     */
    public static getApplications({
        requestBody,
    }: {
        requestBody: ApplicationFilters,
    }): CancelablePromise<Array<Application>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/all',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get application by id
     * @returns Application Application successfully retrieved
     * @throws ApiError
     */
    public static getApplicationById({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<Application> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/application/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete application by id
     * @returns any Application successfully deleted
     * @throws ApiError
     */
    public static deleteApplicationById({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/application/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Copy application with given id
     * @returns Application Application successfully copied
     * @throws ApiError
     */
    public static copyApplication({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<Application> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/application/{id}/copy',
            path: {
                'id': id,
            },
            errors: {
                400: `Proposal validation error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download order document with accompany documents
     * @returns string Order document successfully downloaded
     * @throws ApiError
     */
    public static downloadPublicOrder({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/new/application/{id}/order',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download order document with accompany documents
     * @returns string Order document successfully downloaded
     * @throws ApiError
     */
    public static downloadOrder({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/application/{id}/order',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Upload manually signed document
     * @returns any Application successfully exported
     * @throws ApiError
     */
    public static signApplicationManually({
        id,
        formData,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
        formData: {
            files: Blob;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/{id}/sign/manual',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Validation error`,
                403: `Forbidden`,
                417: `Virus detected in file`,
                422: `File is encrypted or unsupported file type`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Confirm externally handed signed document
     * @returns any Application successfully updated
     * @throws ApiError
     */
    public static signApplicationExternally({
        id,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/{id}/sign/extern',
            path: {
                'id': id,
            },
            errors: {
                400: `Validation error`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate new application
     * @returns ValidationResponse Validation successfully executed
     * @throws ApiError
     */
    public static validatePublicApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<ValidationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/new/application/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Validate new application
     * @returns ValidationResponse Validation successfully executed
     * @throws ApiError
     */
    public static validateApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<ValidationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Calculate prices for the application
     * @returns CalculationResponse Calculation successfully executed
     * @throws ApiError
     */
    public static calculateApplication({
        requestBody,
    }: {
        requestBody: Application,
    }): CancelablePromise<CalculationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/application/calculate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Get status of the signature process for given application
     * @returns InSignStatus Signature status received
     * @throws ApiError
     */
    public static getApplicationSignatureStatus({
        id,
    }: {
        /**
         * application identifier
         */
        id: string,
    }): CancelablePromise<InSignStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/application/{id}/signature/status',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
                512: `Insign service unavailable`,
            },
        });
    }

    /**
     * Load signature status for the application
     * @returns InSignStatus Dashboard created
     * @throws ApiError
     */
    public static loadApplicationSignature({
        id,
    }: {
        /**
         * Application identifier
         */
        id: string,
    }): CancelablePromise<InSignStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/application/{id}/signature/load',
            path: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
                512: `Insign service unavailable`,
            },
        });
    }

    /**
     * Callback from insign
     * @returns any Tariffs successfully received
     * @throws ApiError
     */
    public static callbackApplication({
        id,
        sessionid,
        eventid,
        docid,
        issued,
    }: {
        /**
         * Application unique identifier
         */
        id: string,
        /**
         * Insign session unique identifier
         */
        sessionid: string,
        /**
         * Insign event type
         */
        eventid: string,
        /**
         * Insign document id
         */
        docid?: string,
        /**
         * The type when the session was issued
         */
        issued?: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/application/{id}/callback',
            path: {
                'id': id,
            },
            query: {
                'sessionid': sessionid,
                'eventid': eventid,
                'docid': docid,
                'issued': issued,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
