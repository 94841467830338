import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Button, { buttonClasses } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField } from 'models';
import { ApplicationService } from 'models/extension-generated';
import { ApiError } from 'models/portal-generated';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useApplicaitionPersistance } from '../../hooks/useApplicationPersistance';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';
import { IncompletePersonDialog } from './IncompletePersonDialog';
import { SignatureDialog } from './SignatureDialog';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';

type ContinueButtonProps = {
  title: string;
  isOnLastPage: boolean;
};

export const ContinueButton = ({ title, isOnLastPage }: ContinueButtonProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isIncompletePersonDialogOpen, setIsIncompletePersonDialogOpen] = useState(false);
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const { next, ScreenOrder, currentScreen } = useWizardNavigation();
  const { reducer, state, partnerId } = useHealthInsuranceContext();
  const { isValidating, validateNextPage } = useApplicationValidation(state, reducer, partnerId);
  const { save, isLoading: isSaving } = useApplicaitionPersistance();
  const { updateValue } = reducer;
  const navigate = useNavigate();

  const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
  const isProcessing = isSubmitting || isValidating || isSaving;
  const isProcessingOnLastPage = isProcessing && isOnLastPage;

  const handleManualSignature = async () => {
    try {
      const { id } = await ApplicationService.submitApplication({
        requestBody: { ...state.model }
      });
      setIsSubmitted(true);
      setIsSignatureDialogOpen(false);
      navigate(`/360/antrag/${id}`);
    } catch (error) {
      toast.error('digitalSignatureFailed');
    }
  };

  const handleDigitalSignature = async () => {
    try {
      const { id } = await ApplicationService.submitApplication({
        requestBody: { ...state.model }
      });
      setIsSubmitted(true);
      const response = await ApplicationService.signApplicationDigitally({
        id
      });
      const inSignRedirectUrl = response.inSignSession?.redirectUrl;

      if (inSignRedirectUrl) {
        window.open(inSignRedirectUrl, '_blank');
      }
      setIsSignatureDialogOpen(false);
      navigate(`/360/antrag/${id}`);
    } catch (error) {
      toast.error('digitalSignatureFailed');
    }
  };

  const updateApplicationId = (newId: string) => {
    if (!state.model.id) {
      updateValue({ key: 'id', value: newId });
    }
  };

  const handleContinueClick = async () => {
    try {
      const isHealthQuestions = currentScreen === WizardRoutesEnum.HealthQuestions;
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size === 0;

      if (isHealthQuestions && validationResult.size > 0) {
        setIsIncompletePersonDialogOpen(true);
        return;
      }

      if (hasNoErrors) {
        const saveResult = applicationStart ? await save(state.model) : { id: undefined };

        if (saveResult.id) {
          updateApplicationId(saveResult.id);
        }

        // if current screen is summary screen then submit application
        if (currentScreen === WizardRoutesEnum.Summary) {
          setIsSubmitting(true);
          setIsSignatureDialogOpen(true);
          return;
        }

        next(state.model.id || saveResult.id);
      }
    } catch (e) {
      if (e instanceof ApiError || e instanceof Error) {
        toast.warning(e.message);
      }
    }
  };

  const renderIcon = () => {
    if (isProcessing) {
      return (
        <span className="px-[3px]">
          <CircularProgress color="inherit" size={16} />
        </span>
      );
    }
    if (isOnLastPage) {
      if (isSubmitted) {
        return <CheckIcon />;
      }
      return null;
    }
    return <ArrowForwardOutlinedIcon />;
  };

  const closeSignatureDialog = () => {
    setIsSignatureDialogOpen(false);
    setIsSubmitting(false);
  };

  const closeIncompletePersonDialog = () => {
    setIsIncompletePersonDialogOpen(false);
    setIsSubmitting(false);
  };

  return (
    <>
      {isSignatureDialogOpen && (
        <SignatureDialog
          isOpen={isSignatureDialogOpen}
          onClose={closeSignatureDialog}
          onManualSignatureClick={handleManualSignature}
          onDigitalSignatureClick={handleDigitalSignature}
        />
      )}
      {isIncompletePersonDialogOpen && (
        <IncompletePersonDialog
          isOpen={isIncompletePersonDialogOpen}
          onClose={closeIncompletePersonDialog}
        />
      )}
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={handleContinueClick}
        endIcon={renderIcon()}
        classes={{
          disabled: isSubmitted ? buttonClasses.disabled : 'bg-highlight-80 text-white-100'
        }}
        disabled={isProcessing || isSubmitted}
      >
        {isProcessingOnLastPage ? t('actions.nextStepSumProgress') : title}
      </Button>
    </>
  );
};
