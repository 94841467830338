import { ColDef, GroupCellRendererParams, ICellRendererParams } from 'ag-grid-community';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar, Typography } from '@mui/material';
import { colors } from 'styles/colors';

import { DarkTooltip } from 'lkh-portal-ui-library';
import { Application } from 'models/extension-generated';

import i18n from 'services/translation';

import { TableActionMenu } from '../TableActionMenu';
import { ColumnIds } from './constants';
import { VALUE_GETTERS } from './valueFormatter';
import { TableTooltips } from 'components/TableTooltip';
import { TableHeaderTooltip } from 'components/Tooltips/TableHeaderTooltip';
import { formatDateToGermanForm } from 'utilities/dates';

type ColParams = {
  handleOnDeleteApplicationClick: (id: string) => void;
  handleOnDuplicateApplicationClick: (id: string) => void;
  handleOnSignApplicationClick: (id: string) => void;
  handleOnMenuClose: () => void;
};

export const cols = ({
  handleOnSignApplicationClick,
  handleOnDuplicateApplicationClick,
  handleOnDeleteApplicationClick,
  handleOnMenuClose
}: ColParams): Array<ColDef<Application>> => {
  const defaultCols: Array<ColDef<Application>> = [
    {
      cellClass: 'flex items-center',
      headerName: i18n.t('table.creationDate', { ns: 'dashboard' }),
      flex: 2,
      tooltipComponent: TableHeaderTooltip,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      field: ColumnIds.CreatedAt,
      valueGetter: VALUE_GETTERS[ColumnIds.CreatedAt],
      cellRenderer: (props: GroupCellRendererParams<Application>) => {
        // TO-DO: this make break if you change input storing
        return (
          <Typography variant="bodySMRegular" className="text-text-80">
            {formatDateToGermanForm(props.value)}
          </Typography>
        );
      },
      sortable: true
    },
    {
      flex: 3,
      headerName: i18n.t('table.id', { ns: 'dashboard' }),
      field: ColumnIds.id,
      minWidth: 120,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      valueGetter: VALUE_GETTERS[ColumnIds.id],
      cellRenderer: (props: { value: string }) => {
        return (
          <Typography variant="bodySMRegular" className="text-text-80">
            {props.value}
          </Typography>
        );
      },
      sortable: true,
      cellClass: 'flex items-center'
    },
    {
      cellClass: 'flex items-center',
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      flex: 3.5,
      headerName: i18n.t('table.holder.short', { ns: 'dashboard' }),
      field: ColumnIds.HolderName,
      headerTooltip: i18n.t('table.holder.long', { ns: 'dashboard' }),
      tooltipComponent: TableHeaderTooltip,
      valueGetter: VALUE_GETTERS[ColumnIds.HolderName],
      cellRenderer: (props: { value: { text: string; data: Application } }) => {
        return (
          <div translate="no" className="flex gap-[10px] items-center">
            <Avatar sx={{ bgcolor: colors.surface[60], width: 24, height: 24 }}>
              <PersonOutlineIcon className="text-text-80" sx={{ width: 16, height: 16 }} />
            </Avatar>
            <Typography variant="bodySMRegular" className="text-text-80">
              {props.value.text}
            </Typography>
          </div>
        );
      },
      sortable: true
    },
    {
      flex: 1.5,
      cellClass: 'flex items-center',
      field: ColumnIds.PartnersCount,
      headerName: i18n.t('table.insuredPersons.short', { ns: 'dashboard' }),
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      valueGetter: VALUE_GETTERS[ColumnIds.PartnersCount],
      headerTooltip: i18n.t('table.insuredPersons.long', { ns: 'dashboard' }),
      tooltipComponent: TableHeaderTooltip,
      cellRenderer: (props: { value: { text: string; data: Application } }) => {
        return (
          <DarkTooltip
            title={<TableTooltips.InsuredPartnersApplication {...props.value.data} />}
            followCursor
          >
            <div className="flex gap-[10px] items-center">
              <Avatar sx={{ bgcolor: colors.surface[60], width: 24, height: 24 }}>
                <PeopleAltIcon className="text-text-80" sx={{ width: 16, height: 16 }} />
              </Avatar>
              <Typography variant="bodySMRegular" className="text-text-80">
                {props.value.text}
              </Typography>
            </div>
          </DarkTooltip>
        );
      },
      sortable: true
    },
    {
      cellClass: 'flex items-center',
      flex: 2,
      headerName: i18n.t('table.status', { ns: 'dashboard' }),
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      field: ColumnIds.Status,
      sortable: false,
      headerTooltip: i18n.t('table.status', { ns: 'dashboard' }),
      tooltipComponent: TableHeaderTooltip,
      cellRenderer: (props: ICellRendererParams<Application>) => {
        const status = i18n.t(`ApplicationStatusEnum.${props.value}`, { ns: 'enums' });
        return (
          <div className={'bg-surface-60 px-[12px] rounded'}>
            <Typography variant="bodySMBold" className="text-text-80">
              {status}
            </Typography>
          </div>
        );
      }
    },
    // Action column
    {
      cellClass: 'flex items-center justify-end',
      headerName: '',
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      flex: 1,
      sortable: false,
      field: ColumnIds.id,
      cellRenderer: TableActionMenu,
      cellRendererParams: (params: ICellRendererParams<Application>) => {
        return {
          ...params,
          onSignApplicationClick: handleOnSignApplicationClick,
          onDuplicateApplicationClick: handleOnDuplicateApplicationClick,
          onDeleteApplicationClick: handleOnDeleteApplicationClick,
          onMenuClose: handleOnMenuClose
        };
      }
    }
  ];

  return defaultCols;
};
