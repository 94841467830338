import { forwardRef, PropsWithChildren, ReactNode, useImperativeHandle } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { Accordion } from 'components/Accordion';
import { useToggle } from 'usehooks-ts';

export type AccordionHandle = {
  /**
   * Manually opens the accordion
   */
  open: () => void;
  /**
   * Manually closes the accordion
   */
  close: () => void;
  /**
   * Manually toggles the current accordion state
   */
  toggle: () => void;
};

type SectionAccordionProps = PropsWithChildren & { header: ReactNode };

export const SectionAccordion = forwardRef<AccordionHandle, SectionAccordionProps>(
  ({ children, header }, ref) => {
    const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false);

    useImperativeHandle(ref, () => {
      return {
        open() {
          setIsOpen(true);
        },
        close() {
          setIsOpen(false);
        },
        toggle() {
          toggleIsOpen();
        }
      };
    });

    return (
      <Accordion
        expanded={isOpen}
        onChange={toggleIsOpen}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          className="border border-solid border-text-80 border-t-0 border-r-0 border-b-[1px] border-l-0 pt-[12px] pr-[12px] pb-[12px] pl-[0px]"
          expandIcon={isOpen ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
        >
          {header}
        </AccordionSummary>
        <AccordionDetails className="p-0">{children}</AccordionDetails>
      </Accordion>
    );
  }
);
