import { deDE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

import { muiAutocompleteOverride } from './components/muiAutocomplete';
import { muiButtonOverride } from './components/muiButton';
import { muiButtonBaseOverride } from './components/muiButtonBase';
import { muiCheckboxOverride } from './components/muiCheckbox';
import { muiChipOverride } from './components/muiChip';
import { muiPickerDayOverride } from './components/muiDatePicker';
import { muiDialogOverride } from './components/muiDialog';
import { muiIconButtonOverride } from './components/muiIconButton';
import { muiInputAdornmentOverride } from './components/muiInputAdornment';
import { muiLinkOverride } from './components/muiLink';
import { muiListSubheaderOverride } from './components/muiListSubheader';
import { muiMenuItemOverride } from './components/muiMenuItem';
import { muiModalOverride } from './components/muiModal';
import { muiMuiListItemText } from './components/muiMuiListItemText';
import { muiPaperOverride } from './components/muiPaper';
import { muiPopoverOverride } from './components/muiPopover';
import { muiRadioOverride } from './components/muiRadioOverride';
import { muiSelectOverride } from './components/muiSelect';
import { muiTabOverride } from './components/muiTab';
import { muiTabsOverride } from './components/muiTabs';
import { muiTextFieldOverride } from './components/muiTextField';
import { muiToggleButtonOverride } from './components/muiToggleButton';
import { muiTooltipOverride } from './components/muiTooltip';
import { muiTypographyOverride } from './components/muiTypography';
import { paletteOverride } from './palette';
import { typographyOverride } from './typography';

export const theme = createTheme(
  {
    typography: typographyOverride,
    palette: paletteOverride,
    components: {
      MuiPopover: muiPopoverOverride,
      MuiDialog: muiDialogOverride,
      MuiModal: muiModalOverride,
      MuiChip: muiChipOverride,
      MuiAutocomplete: muiAutocompleteOverride,
      MuiIconButton: muiIconButtonOverride,
      MuiPaper: muiPaperOverride,
      MuiButton: muiButtonOverride,
      MuiButtonBase: muiButtonBaseOverride,
      MuiMenuItem: muiMenuItemOverride,
      MuiListItemText: muiMuiListItemText,
      MuiListSubheader: muiListSubheaderOverride,
      MuiSelect: muiSelectOverride,
      MuiToggleButton: muiToggleButtonOverride,
      MuiInputAdornment: muiInputAdornmentOverride,
      MuiTabs: muiTabsOverride,
      MuiTab: muiTabOverride,
      MuiTextField: muiTextFieldOverride,
      MuiLink: muiLinkOverride,
      MuiCheckbox: muiCheckboxOverride,
      MuiRadio: muiRadioOverride,
      MuiTypography: muiTypographyOverride,
      MuiTooltip: muiTooltipOverride,
      MuiPickersDay: muiPickerDayOverride
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 800,
        lg: 992,
        xl: 1200
      }
    }
  },
  deDE
);
