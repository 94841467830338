import figmaTypographyTokens from '../tokens/text.styles.tokens.json';
import { Components, Theme } from '@mui/material/styles';

const lkhTypographyRoot = figmaTypographyTokens['lkh-text'];

export const muiInputAdornmentOverride: Components<Omit<Theme, 'components'>>['MuiInputAdornment'] =
  {
    styleOverrides: {
      root: {
        '&.MuiInputAdornment-root.MuiInputAdornment-positionStart': {
          '& > span': lkhTypographyRoot['Body-lg'].light.$value
        },
        '&.MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
          '& > span': lkhTypographyRoot['Body-lg'].light.$value
        },
        height: 'inherit'
      }
    }
  };
