import { useEffect, useState } from 'react';

import { GridApi, GridReadyEvent, RowClassParams, SortChangedEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import { cols, ColumnIds } from './models';
import { Table as AgGridTable, Loader, LoaderType, Props } from 'lkh-portal-ui-library';

import { DeleteApplicationDialog } from './DeleteApplicationDialog/DeleteApplicationDialog';
import { DuplicateApplicationDialog } from './DuplicateApplicationDialog';
import { SignApplicationDialog } from './SignApplicationDialog';
import { NoResult } from 'components/NoResult/NoResult';
import { SortingOrder } from 'hooks/useSort';
import { useDashboardPageContext } from 'pages/Dashboard/context';
import { useApplicaitionPersistance } from 'pages/PrivateHealthInsurance/hooks/useApplicationPersistance';

export const Table = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { t } = useTranslation('common');
  const { drop } = useApplicaitionPersistance();
  const { loading, state, actions } = useDashboardPageContext();
  const { applications } = state;
  const { setSortBy } = actions;
  const [isSignDialogOpen, setSignDialogOpen] = useState(false);
  const [isDeletionDialogOpen, setDeletionDialogOpen] = useState(false);
  const [isDuplicationDialogOpen, setDuplicationDialogOpen] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState('');

  const processSign = () => {
    actions.confirmDialog();
  };

  const processDuplicate = () => {
    actions.confirmDialog();
  };

  const processDeletion = async () => {
    await drop(selectedApplicationId);
    actions.deleteApplication({ id: selectedApplicationId });
  };

  useEffect(() => {
    if (gridApi) {
      if (loading && gridApi) {
        //Fix implemented based on github issue https://github.com/ag-grid/ag-grid/issues/3849
        setTimeout(() => {
          gridApi.showLoadingOverlay();
        });
      }
      if (!loading && applications.length === 0) {
        gridApi.showNoRowsOverlay();
      }
      if (!loading && applications.length > 0) {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi]);

  const handleGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const handleSortChange = (e: SortChangedEvent) => {
    const sortingColumn = e.columnApi.getColumns()?.find((col) => col.isSorting());
    const colId = sortingColumn?.getColId();

    const resolvedOrder: SortingOrder | null = (() => {
      const agSortValue = sortingColumn?.getSort();

      if (agSortValue === 'desc') return SortingOrder.DESCENDING;
      if (agSortValue === 'asc') return SortingOrder.ASCENDING;

      return null;
    })();

    // reset sorting if no column is sorted
    if (!resolvedOrder) {
      setSortBy({
        columnId: ColumnIds.CreatedAt,
        order: SortingOrder.DESCENDING
      });
      return;
    }

    setSortBy({
      columnId: colId as ColumnIds,
      order: resolvedOrder
    });
  };

  const handleSignApplicationStatus = (id: string) => {
    setSignDialogOpen(true);
    setSelectedApplicationId(id);
  };

  const handleApplicationDelete = (id: string) => {
    setDeletionDialogOpen(true);
    setSelectedApplicationId(id);
  };

  const handleApplicationDuplicate = (id: string) => {
    setDuplicationDialogOpen(true);
    setSelectedApplicationId(id);
  };

  const handleOnMenuClose = () => {
    gridApi?.deselectAll();
    gridApi?.clearFocusedCell();
    setDeletionDialogOpen(false);
    setSignDialogOpen(false);
    setDuplicationDialogOpen(false);
  };

  function renderOddRowDifferentColor(params: RowClassParams) {
    const rowIndex = params.node.rowIndex || 0;
    if (rowIndex % 2 === 0) {
      return 'rounded-lg';
    }
    return 'bg-surface-60 rounded-lg';
  }

  return (
    <div>
      <AgGridTable
        className="table-override mt-[16px]"
        rowHeight={50}
        columnDefs={cols({
          handleOnDuplicateApplicationClick: handleApplicationDuplicate,
          handleOnSignApplicationClick: handleSignApplicationStatus,
          handleOnDeleteApplicationClick: handleApplicationDelete,
          handleOnMenuClose: handleOnMenuClose
        })}
        rowData={applications}
        rowSelection="single"
        suppressCellFocus
        tooltipMouseTrack
        getRowClass={renderOddRowDifferentColor}
        defaultColDef={{
          flex: 2,
          sortable: false,
          comparator: () => 0,
          suppressMovable: true,
          suppressSizeToFit: false
        }}
        tooltipShowDelay={0}
        onSortChanged={(e) => handleSortChange(e)}
        onGridReady={(e) => handleGridReady(e)}
        loadingOverlayComponent={Loader}
        loadingOverlayComponentParams={{
          type: LoaderType.Circular,
          color: Props.BaseColors.Primary.Base
        }}
        noRowsOverlayComponent={NoResult}
        noRowsOverlayComponentParams={{
          warningMessage: t('table.warningMessage'),
          warningDescription: t('table.warningDescription')
        }}
      />
      {isSignDialogOpen && (
        <SignApplicationDialog
          appId={selectedApplicationId}
          isOpen={isSignDialogOpen}
          onClose={() => setSignDialogOpen(false)}
          process={processSign}
        />
      )}
      {isDuplicationDialogOpen && (
        <DuplicateApplicationDialog
          appId={selectedApplicationId}
          isOpen={isDuplicationDialogOpen}
          onClose={() => setDuplicationDialogOpen(false)}
          process={processDuplicate}
        />
      )}
      {isDeletionDialogOpen && (
        <DeleteApplicationDialog
          appId={selectedApplicationId}
          isOpen={isDeletionDialogOpen}
          onClose={() => setDeletionDialogOpen(false)}
          process={processDeletion}
        />
      )}
    </div>
  );
};
