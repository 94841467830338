import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { List, ListItem, Typography } from '@mui/material';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

export const ValidationError = () => {
  const { state } = useHealthInsuranceContext();

  if (state.validationResults.size > 0) {
    const getErrorList = () => {
      const listItems = Array.from(state.validationResults).map(([key, value]) => (
        <ListItem className="p-0" key={key}>
          <Typography className="text-sm font-normal">
            {!value?.success && value?.error?.issues[0].message}
          </Typography>
        </ListItem>
      ));
      return <List className="p-0">{listItems}</List>;
    };

    const getFirstError = () => {
      if (!state.validationResults.entries().next().value[1].success) {
        return state.validationResults.entries().next().value[1].error.issues[0].message;
      } else {
        return null;
      }
    };

    return (
      <DarkTooltip
        children={
          <div className="flex items-center gap-[8px] text-danger-60">
            <Typography className="flex justify-center items-center gap-[4px] whitespace-nowrap text-sm font-normal">
              <span className="inline-block text-ellipsis overflow-hidden max-w-[150px]">
                {getFirstError()}
              </span>
              <span>
                {state.validationResults.size > 1 && ` + ${state.validationResults.size - 1}`}
              </span>
            </Typography>
            <ErrorOutlineIcon className="text-danger-60" sx={{ width: 16, height: 16 }} />
          </div>
        }
        title={getErrorList()}
        arrow
        placement="top"
      />
    );
  }

  return <></>;
};
