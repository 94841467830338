import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { CheckCircleIcon, Icon, Props } from 'lkh-portal-ui-library';

import { useUploadContext } from 'contexts/UploadContext';

export const Complete = () => {
  const { t } = useTranslation('view360', { keyPrefix: 'upload' });
  const { close } = useUploadContext();

  return (
    <div className="row text-center py-[24px] w-full">
      <div className="col-12 mb-[26px]">
        <Icon icon={<CheckCircleIcon />} color={Props.BaseColors.LogoGreen.Base} size="xl" />
      </div>
      <div className="col-12 mb-[24px]">
        <div className="mb-[8px]">
          <Typography variant="bodyMDBold">{t('complete.title')}</Typography>
        </div>
        <div>
          <Typography className="text-text-60">{t('complete.description')}</Typography>
        </div>
      </div>
      <div className="col-12">
        <Button onClick={close} size="large">
          {t('actions.close')}
        </Button>
      </div>
    </div>
  );
};
