import { createTheme } from '@mui/material';
import { colors } from 'styles/colors';

import { theme } from '../theme';

export const filterTheme = createTheme(
  {
    components: {
      MuiTextField: {
        defaultProps: {
          InputProps: {
            classes: {
              input: 'text-[14px] text-text-80'
            }
          },
          InputLabelProps: {
            classes: {
              root: 'text-[14px] text-text-80'
            }
          },
          fullWidth: true,
          variant: 'standard',
          size: 'small'
        },
        styleOverrides: {
          root: {
            '&:hover': {
              '& .MuiInputBase-root': {
                '::before': {
                  borderColor: `${colors.primary[80]} !important`
                }
              }
            },
            fontSize: '14px',
            color: colors.text[80]
          }
        }
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
          disableRipple: true,
          classes: {
            root: 'h-[24px]'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            color: colors.text[80]
          }
        }
      },
      MuiAccordion: {
        defaultProps: {
          disableGutters: true
        },
        styleOverrides: {
          root: {
            '::before': {
              display: 'none'
            },
            '& .MuiAccordionSummary-root': {
              height: '1px !important',
              minHeight: '1px !important'
            },
            boxShadow: 'none'
          }
        }
      },

      MuiAutocomplete: {
        defaultProps: {
          getOptionLabel: (option) => option.label || '',
          isOptionEqualToValue: (option, value) => option.value === value.value,
          renderTags: (tagValue, _getTagProps, ownerState) => {
            if (ownerState.focused && tagValue.length > 0) {
              return <p className="text-[14px] text-text-80 m-0">({tagValue.length})&nbsp;</p>;
            }
            if (tagValue.length > 1) {
              return (
                <div className="inline-flex text-[14px] truncate text-text-80 m-0 w-auto s:w-[50%] m:w-[73px] l:w-[70%]">
                  <p className="m-0 w-[80%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {tagValue[0].label}
                  </p>
                  <p className="m-0">&nbsp;+ {tagValue.length - 1}</p>
                </div>
              );
            }
            return tagValue.map((option) => (
              <p
                key={option}
                className="text-[14px] text-text-80 m-0 whitespace-nowrap overflow-hidden overflow-ellipsis w-[70%]"
              >
                {option.label}
              </p>
            ));
          }
        },
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              fontSize: '14px',
              color: colors.text[80],
              paddingRight: '20px !important'
            },
            '& .MuiInputLabel-root': {
              fontSize: '14px',
              color: colors.text[80]
            }
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            height: 'inherit'
          }
        }
      }
    }
  },
  theme
);
