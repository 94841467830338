import { ColumnIds } from '../components/Table/models';
import { Application, ApplicationFilters, ApplicationStatusEnum } from 'models/extension-generated';

import { SortingOrder } from 'hooks/useSort';

export type DateRange = {
  from?: string;
  to?: string;
};
interface Action<T, P> {
  type: T;
  payload: P;
}

export enum ApplicationFilterActions {
  SET_DATE_RANGE,
  SET_STATUS,
  RESET_FILTER,
  SET_ACTIVE_TAB,
  SET_SORT_BY,
  SET_APPLICATIONS,
  DELETE_APPLICATION,
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_ID
}

export type SetSortByPayload = { columnId: ColumnIds; order: SortingOrder };
export type DeleteApplicationPayload = { id: string };

export type ApplicationFilterAction =
  | Action<ApplicationFilterActions.SET_DATE_RANGE, DateRange>
  | Action<ApplicationFilterActions.SET_STATUS, Array<ApplicationStatusEnum> | undefined>
  | Action<ApplicationFilterActions.SET_ACTIVE_TAB, TabType>
  | Action<ApplicationFilterActions.SET_SORT_BY, SetSortByPayload>
  | Action<ApplicationFilterActions.SET_APPLICATIONS, Array<Application>>
  | Action<ApplicationFilterActions.RESET_FILTER, null>
  | Action<ApplicationFilterActions.DELETE_APPLICATION, DeleteApplicationPayload>
  | Action<ApplicationFilterActions.SET_FIRSTNAME, string>
  | Action<ApplicationFilterActions.SET_LASTNAME, string>
  | Action<ApplicationFilterActions.SET_ID, string>;

export type ActionFunction<T = undefined> = (
  state: ApplicationsState,
  payload?: T
) => ApplicationsState;

export enum TabType {
  OpenApplications = 'OpenApplications',
  SubmittedApplications = 'SubmittedApplications'
}

export type ApplicationsState = {
  applications: Array<Application>;
  filters: ApplicationFilters;
  selectedTab: TabType;
};
