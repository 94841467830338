import { useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';

import { downloadForm } from 'utilities/fetchDocument';

type ShowDownloadFormProps = {
  documentInfo: readonly [formId: string, formName: string];
};

export const ShowDownloadForm = ({ documentInfo }: ShowDownloadFormProps) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [formId, formName] = documentInfo;

  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
      const response = await downloadForm(formId);

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.download = formName;
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleOpen = async () => {
    const doc = await downloadForm(formId);
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank', 'noreferrer');
  };

  return (
    <div className="flex items-center justify-center gap-[12px]">
      <LaunchIcon onClick={handleOpen} className="cursor-pointer" />
      {!downloadLoading ? (
        <FileDownloadOutlinedIcon
          onClick={handleDownload}
          className="cursor-pointer"
          data-testid="download-button"
        />
      ) : (
        <CircularProgress className="text-text-80" size={24} />
      )}
    </div>
  );
};
