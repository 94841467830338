import { useNavigate } from 'react-router';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography
} from '@mui/material';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField } from 'models';
import { ApiError } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useApplicaitionPersistance } from '../../hooks/useApplicationPersistance';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';

type HomeNavigationValidationDialogProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
};

export const HomeNavigationValidationDialog = ({
  isDialogOpen: isWizardDialogOpen,
  onDialogClose
}: HomeNavigationValidationDialogProps) => {
  const { t } = useTranslation('wizardValidation');
  const navigate = useNavigate();
  const { ScreenOrder, currentScreen } = useWizardNavigation();
  const { reducer, state, partnerId } = useHealthInsuranceContext();
  const { save, isLoading: isSaving } = useApplicaitionPersistance();
  const { isValidating, validateNextPage } = useApplicationValidation(state, reducer, partnerId);
  const { updateValue } = reducer;
  const isValidatingOrSaving = isValidating || isSaving;

  const updateApplicationId = (newId: string) => {
    if (!state.model.id) {
      updateValue({ key: 'id', value: newId });
    }
  };

  const navigateToDashBoardPage = () => {
    navigate('/dashboard');
  };

  const leaveAndSave = async () => {
    try {
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size === 0;
      const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
      if (!applicationStart) {
        toast.warning(t<string>('common:toastMessages.missingApplicationStartDate'), {
          delay: 500
        });
        return;
      }
      if (hasNoErrors) {
        const saveResult = await save(state.model);

        if (saveResult.id) {
          updateApplicationId(saveResult.id);
        }
        navigateToDashBoardPage();
      }
    } catch (e) {
      if (e instanceof ApiError) {
        toast.warning(e.message);
      }
    } finally {
      onDialogClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={isWizardDialogOpen} onClose={onDialogClose}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <ErrorOutlineOutlinedIcon className="text-warning-60 text-2xl" />
          <div className="pl-[16px]">
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('validationModal.header')}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                {t('validationModal.description')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="flex justify-between mt-[24px]">
          <Button size="large" onClick={onDialogClose}>
            {t('validationModal.cancelBtn')}
          </Button>
          <div className="flex justify-between gap-[16px]">
            <Button size="large" onClick={navigateToDashBoardPage}>
              {t('validationModal.cancelProcessBtn')}
            </Button>
            <Button
              size="large"
              variant="contained"
              startIcon={
                isValidatingOrSaving ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <SaveOutlinedIcon />
                )
              }
              onClick={leaveAndSave}
              disabled={isValidatingOrSaving}
            >
              {t('validationModal.saveAndCloseBtn')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
