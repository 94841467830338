import { ValueGetterFunc } from 'ag-grid-community';

import { Application, PersonRoleEnum } from 'models/extension-generated';

import i18n from 'services/translation';

import { ColumnIds } from './constants';
import { formatShortDate } from 'utilities/dates';

/*
  return number with spaces after every 3rd character
*/
function formatIdNumber(id: string): string {
  return id.toString().replace(/\d{3}(?=.)/g, '$& ');
}

export const VALUE_GETTERS: {
  [Key in ColumnIds]?: ValueGetterFunc<Application>;
} = {
  [ColumnIds.id]: (params) => {
    const id = params?.data?.id?.toString() || '';
    return formatIdNumber(id);
  },
  [ColumnIds.HolderName]: (params) => {
    const partners = params.data?.partners || [];
    const { firstname, lastname } =
      partners.find((p) => p.roles.includes(PersonRoleEnum.POLICY_HOLDER)) || {};

    if (firstname && lastname) {
      return {
        text: `${lastname}, ${firstname}`,
        data: params?.data
      };
    }

    return {
      text: i18n.t('common:noInsureeText'),
      data: params?.data
    };
  },
  [ColumnIds.PartnersCount]: (params) => {
    const partners = params.data?.partners || [];
    const insuredPartners = partners.filter((p) => p.roles.includes(PersonRoleEnum.INSURED_PERSON));
    return {
      text: `${insuredPartners.length}`,
      data: params?.data
    };
  },
  [ColumnIds.Status]: (params) => {
    return i18n.t(`table.statusEnum.${params?.data?.status || ''}`, { ns: 'contract' });
  },
  [ColumnIds.CreatedAt]: (params) => {
    return formatShortDate(params?.data?.creationDate || '');
  }
};
