import { useState } from 'react';

import differenceBy from 'lodash/differenceBy';

import { FilterGroup } from 'models/criteriaTypes';

export type UseFilter = {
  active: Array<FilterGroup> | [];
  set: (group: Array<FilterGroup>, includePrevious?: boolean) => void;
  remove: (key: string | Array<string>) => void;
  reset: () => void;
};

export function useFilter(initialFilters?: Array<FilterGroup>): UseFilter {
  const [active, setActive] = useState<Array<FilterGroup>>(initialFilters || []);

  /**
   * Adds new criteria to the active filters
   */
  const set = (groups: Array<FilterGroup>, includePrevious = true): void => {
    const originalOverlapCriteriaRemoved = differenceBy(active, groups, 'groupId');

    if (!includePrevious) {
      setActive(groups);
      return;
    }

    setActive([...originalOverlapCriteriaRemoved, ...groups]);
  };

  /**
   * Removes criteria from active filters
   */
  const remove = (groupId: string | Array<string>): void => {
    setActive(active.filter((group) => group.groupId !== groupId));
  };

  /**
   * Resets filters
   */
  const reset = () => {
    setActive([]);
  };

  return {
    active,
    set,
    reset,
    remove
  };
}
