import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Chip, Tabs, TabsOwnProps } from '@mui/material';
import Tab from '@mui/material/Tab';

import { SearchOperationEnum } from 'models/portal-generated';

import { cols } from '.';
import { Grid } from './Grid';
import { SecondLevelTabs } from './SecondLevelTabs/SecondLevelTabs';
import { useFetchCount } from './utils/useFetchCount';
import { useContractPageContext } from 'pages/Contracts/context';
import { SecondLevelTabsProps } from 'pages/Contracts/types';

import styled from './GridGroup.module.scss';

const TabLabel = ({ count, label }: { count: number; label: string }) => {
  return (
    <div className="flex items-center">
      <Chip
        color="primary"
        label={
          <p className="font-bold text-12 leading-16 tracking-tight text-white-100">{count}</p>
        }
      />
      <p className="ml-[8px] font-bold text-12 leading-20 text-text-80">{label}</p>
    </div>
  );
};

export enum TabType {
  Contracts = 'contracts',
  Reminders = 'reminders'
}

export const GridGroup = () => {
  const [selectedTab, setSelectedTab] = useState(TabType.Contracts);
  const { filters, pagination, sorting } = useContractPageContext();

  const { data } = useFetchCount();
  const [secondarySelectedTab, setSecondarySelectedTab] = useState(SecondLevelTabsProps.Contract);
  const { t } = useTranslation('contract');

  const handleTabChange: TabsOwnProps['onChange'] = (_event, value: string | number) => {
    pagination.goTo(0);
    sorting.reset();

    if (value === TabType.Contracts) {
      setSelectedTab(TabType.Contracts);
      filters.remove('hasReminder');
      return;
    }

    // since there are only two options and Tab contracts is handled above default
    // case is to set filter for reminders (mahnugen)
    setSelectedTab(TabType.Reminders);
    filters.set([
      {
        groupId: 'hasReminder',
        groupKey: 'hasReminder',
        criteria: [
          {
            key: 'hasReminder',
            operation: SearchOperationEnum.EQUALS,
            value: true
          }
        ]
      }
    ]);
  };

  return (
    <>
      <div className={classNames(styled.tabGroup, 'pt-24')}>
        <Tabs
          indicatorColor="secondary"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={<TabLabel count={data?.contracts || 0} label={t('contract:tab.contracts')} />}
            value={TabType.Contracts}
          />
          <Tab
            label={<TabLabel count={data?.reminders || 0} label={t('contract:tab.reminders')} />}
            value={TabType.Reminders}
          />
        </Tabs>
      </div>
      <div className="mt-[16px]">
        <SecondLevelTabs
          selectedTab={secondarySelectedTab}
          setSelectedTab={setSecondarySelectedTab}
        />
      </div>
      <Grid
        selectedTab={selectedTab}
        secondarySelectedTab={secondarySelectedTab}
        setSelectedTab={setSelectedTab}
        columnDef={cols()}
      />
    </>
  );
};
