import { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UTurnRightOutlinedIcon from '@mui/icons-material/UTurnRightOutlined';
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import {
  Partner,
  PersonRoleEnum,
  TariffCalculationResult
} from '../../../../models/extension-generated';
import { DarkTooltip } from 'lkh-portal-ui-library';

import { useApplicationHelpers } from '../../../../hooks/useApplicationHelpers/useApplicationHelpers';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useApplicationValidation } from '../../hooks/useApplicationValidation';
import { ValidationError } from '../ValidationError';
import { TariffRow } from './TariffRow/TariffRow';
import { Accordion } from 'components/Accordion';
import { formatNameSidebar } from 'pages/Contract360Page/utils/formatters';
import { useTariffQuery } from 'pages/PrivateHealthInsurance/subpages/TariffData/TarriffQuestions/useTariffQuery';
import { formatPrice } from 'utilities/string';

import styles from './Sidebar.module.scss';

type PartnerSummaryProps = {
  partner: Partner;
  isLoading: boolean;
  price: number;
  tariffs: Array<TariffCalculationResult>;
  isError?: boolean;
};

export const PartnerSummary = ({
  partner,
  price,
  tariffs,
  isLoading = false,
  isError = false
}: PartnerSummaryProps) => {
  const { t } = useTranslation('wizardSidebar');
  const { state, partnerId, selectPartner, reducer } = useHealthInsuranceContext();
  const { isValidating, validateMinimalPartner } = useApplicationValidation(
    state,
    reducer,
    partnerId
  );
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const { tariffs: allTariffsList } = useTariffQuery(partnerId);
  const insuredPartners = useMemo(() => getPartnersByRole(PersonRoleEnum.INSURED_PERSON), [state]);
  const isSelected = useMemo(() => partnerId === partner.id, [partnerId]);
  const [showUnableToSelectError, setShowUnableToSelectError] = useState(false);

  async function handleSwitchToPartner(): Promise<void> {
    try {
      setShowUnableToSelectError(false);
      const validationResult = await validateMinimalPartner();

      if (validationResult.size === 0 && partner.id) {
        selectPartner(partner.id);
      } else {
        setShowUnableToSelectError(true);
      }
    } catch (e) {
      setShowUnableToSelectError(true);
    }
  }

  function handleDeletePartner(): void {
    if (isSelected) {
      const prevIndex = insuredPartners.findIndex((p) => p.id === partnerId) - 1;
      const insuredPartnerId = insuredPartners[prevIndex].id;
      if (insuredPartnerId) {
        selectPartner(insuredPartnerId);
      }
    }
    const roles = partner.roles || [];

    if (roles.includes(PersonRoleEnum.POLICY_HOLDER)) {
      reducer.clearRole({ role: PersonRoleEnum.POLICY_HOLDER });
      reducer.createPartner({ role: PersonRoleEnum.POLICY_HOLDER });
    }

    reducer.removePartner({ id: partner.id || '' });
  }

  const isSwitchDisabled = useMemo(() => {
    return isValidating || partnerId === partner.id;
  }, [state, partnerId]);

  const isRemoveDisabled = useMemo(() => {
    return isValidating || insuredPartners.length === 1;
  }, [state]);

  const hasPartnerError = (partner: Partner): boolean => {
    const partnerId = partner.id;
    if (partnerId)
      return (
        Array.from(state.validationResults.keys()).filter((key) => key.includes(partnerId)).length >
        0
      );
    return false;
  };
  const partnerErrorStyle = hasPartnerError(partner) ? 'text-danger-60' : 'text-text-100';
  const calculationErrorStyle = isError ? 'text-danger-60' : 'text-text-100';
  const isSelectedPartnerStyle = isSelected ? 'font-bold' : 'font-normal';

  const sortedTarriffs = [...tariffs].sort((a, b) => {
    const aValue = a.sequenceOfOrder === undefined ? Number.NEGATIVE_INFINITY : a.sequenceOfOrder;
    const bValue = b.sequenceOfOrder === undefined ? Number.NEGATIVE_INFINITY : b.sequenceOfOrder;
    return aValue > bValue ? 1 : -1;
  });

  const name = formatNameSidebar(partner);

  if (partner.firstname === '' && partner.lastname === '') {
    return null;
  } else {
    return (
      <Accordion
        className="bg-[transparent]"
        defaultExpanded={isSelected}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classNames(
            'bg-transparent border border-b-[1px] border-t-0 border-r-0 border-l-0 border-solid px-[16px] py-[0px] mb-[16px]',
            partnerErrorStyle
          )}
        >
          <span className="flex w-full">
            <Typography
              variant="bodyMDRegular"
              className={classNames(
                'flex-none',
                'w-1/2',
                'text-sm',
                'truncate',
                isSelectedPartnerStyle,
                partnerErrorStyle
              )}
            >
              <DarkTooltip title={name} placement="left" arrow noWrap>
                <span translate="no">{name}</span>
              </DarkTooltip>
            </Typography>
            <Typography
              variant="bodyMDBold"
              className={classNames(
                'grow',
                'text-right',
                'font-bold',
                'text-sm',
                'mr-[26px]',
                calculationErrorStyle
              )}
            >
              {isLoading && <CircularProgress color="primary" size={16} />}
              {price === 0 && !isLoading && '-,-- €'}
              {price !== 0 && !isLoading && formatPrice(price)}
            </Typography>
          </span>
        </AccordionSummary>
        <AccordionDetails className="px-[10px] py-0">
          <Stack
            direction="row"
            justifyContent={'space-between'}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <Button
              size="small"
              disabled={isSwitchDisabled}
              onClick={handleSwitchToPartner}
              variant="text"
              color="primary"
              startIcon={
                isValidating ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <UTurnRightOutlinedIcon sx={{ rotate: '-90deg' }} />
                )
              }
            >
              {t('partner.actions.switchTo')}
            </Button>
            <Button
              size="small"
              onClick={handleDeletePartner}
              disabled={isRemoveDisabled}
              color="error"
              variant="text"
              startIcon={<DeleteOutlinedIcon />}
            >
              {t('partner.actions.delete')}
            </Button>
          </Stack>

          {showUnableToSelectError && (
            <>
              <div className="mt-[20px] mb-[10px]">{t('partner.actions.unableToSwitchInfo')}</div>
              <div className="mb-[20px]">
                <ValidationError />
              </div>
            </>
          )}
          <div
            className={classNames(
              styles.tariffs,
              `${sortedTarriffs.length > 0 ? 'mt-[10px]' : 'mt-[0px]'}`
            )}
          >
            {sortedTarriffs.length > 0 &&
              sortedTarriffs.map((calculationTariff) => {
                const selectedTariffs =
                  partner.applicationInformation?.tariffInformation?.selectedTariffs || [];
                const tariff = selectedTariffs.find(({ id }) => id === calculationTariff.tariffId);

                if (tariff) {
                  return (
                    <TariffRow
                      key={`partner-summary-${tariff.id}`}
                      calculation={calculationTariff}
                      tariff={tariff}
                      tariffsList={allTariffsList}
                      selectedTariffs={selectedTariffs}
                    />
                  );
                }
                return <></>;
              })}
            {sortedTarriffs.length === 0 && !isLoading && (
              <Typography className="text-text-100" fontSize="14px">
                {t('partner.noTariffs')}
              </Typography>
            )}
            {isLoading && (
              <div className="flex justify-center">
                <CircularProgress color="primary" size={16} />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
};
