import { createContext, forwardRef, HTMLAttributes, memo, ReactElement, useContext } from 'react';

import { ListChildComponentProps, VariableSizeList } from 'react-window';

import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { useResetCache } from 'hooks/useResetCache/useResetCache';
import { DropdownOption } from 'pages/Contracts/types';

type ListRowData = [
  HTMLAttributes<HTMLLIElement>,
  DropdownOption,
  number,
  boolean,
  boolean | undefined
];

const SM_UP_SIZE = 36;
const NOT_SM_UP_SIZE = 48;
const LISTBOX_PADDING = 8;
const RENDERED_ITEMS_COUNT = 8;

const renderRow = memo((props: ListChildComponentProps) => {
  const { data, index, style } = props;

  const [elProps, option, optionIndex, optionSelected, allOptionsSelected] = data[
    index
  ] as ListRowData;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  return (
    <Typography
      variant="bodyLGRegular"
      component="li"
      noWrap
      style={inlineStyle}
      {...elProps}
      key={`${option.value}-${optionIndex}`}
    >
      <Checkbox sx={{ mr: 1 }} checked={allOptionsSelected ? allOptionsSelected : optionSelected} />
      <DarkTooltip
        title={Number(option?.label?.length) > 30 ? option.label : ''}
        placement="bottom"
        arrow
      >
        <Typography noWrap>{option.label}</Typography>
      </DarkTooltip>
    </Typography>
  );
});

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
// Adapter for react-window
export const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? SM_UP_SIZE : NOT_SM_UP_SIZE;

    const getChildSize = (child: ReactElement) => {
      if (Object.prototype.hasOwnProperty.call(child, 'group')) {
        return NOT_SM_UP_SIZE;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > RENDERED_ITEMS_COUNT) {
        return RENDERED_ITEMS_COUNT * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: number) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
