import { useState } from 'react';
import { SupportDialog } from '../SupportDialog/SupportDialog';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { useTranslation } from 'react-i18next';

import Chip from '@mui/material/Chip';

//add property to the SupportButton component for if icon or text should shown

export interface SupportButtonProps {
  showText?: boolean;
}

export const SupportButton = ({ showText }: SupportButtonProps) => {
  const { t } = useTranslation('navigation');

  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);

  const handleOpenSupportDialog = () => {
    setIsSupportDialogOpen(true);
  };

  const handleCloseSupportDialog = () => {
    setIsSupportDialogOpen(false);
  };
  return (
    <>
      {isSupportDialogOpen && (
        <SupportDialog
          isDialogOpen={isSupportDialogOpen}
          onDialogClose={handleCloseSupportDialog}
        />
      )}
      {!showText ? (
        <ContactSupportOutlinedIcon
          className="cursor-pointer pointer-events-auto fill-white-100 text-[18px]"
          onClick={handleOpenSupportDialog}
        />
      ) : (
        <Chip
          icon={
            <ContactSupportOutlinedIcon className="cursor-pointer pointer-events-auto fill-white-100 text-[18px]" />
          }
          variant="support"
          className="w-full font-bold text-xs"
          onClick={handleOpenSupportDialog}
          label={t('bottom.support')}
        />
      )}
    </>
  );
};
