import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { getAgentIdFilterOptions, model } from '../models';
import { SearchOperationEnum } from 'models/portal-generated';

import { DropdownOption } from '../../../types';
import MultiSelectAll from '../Multiselect';
import { useTariffs } from '../useTariffs';
import { useUser } from 'hooks/useUser';
import { FilterGroupsEnum } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';

export const ContractFilters = () => {
  const { t } = useTranslation('contract');
  const { agentIds } = useUser();
  const agentIdOptions = getAgentIdFilterOptions(agentIds).list;

  const {
    filters,
    handleListFilterChange,
    handleRangeChange,
    handleDateRangeChange,
    handleTextFilterChange,
    handleTariffFilterChange,
    handleNotTariffFilterChange,
    getSelectedValue,
    getSelectedValues
  } = useContractPageContext();

  const { tariffs } = useTariffs();

  const validFrom = getSelectedValue(FilterGroupsEnum.ValidFrom);
  const policyHolderName = getSelectedValue(FilterGroupsEnum.PolicyHolderName);
  const policyHolderFirstName = getSelectedValue(FilterGroupsEnum.PolicyHolderFirstName);
  const postalCode = getSelectedValue(FilterGroupsEnum.MainContactPostalCode);

  const notTariffs: DropdownOption[] = getSelectedValues(
    FilterGroupsEnum.NotTariffGroupId,
    tariffs
  );
  const hasTariffs: DropdownOption[] = getSelectedValues(FilterGroupsEnum.Tariff, tariffs);

  const productGroup = getSelectedValues(FilterGroupsEnum.TariffGroupId, model.productGroup.list);
  const selectedAgentIds: DropdownOption[] = getSelectedValues(
    FilterGroupsEnum.AgentId,
    agentIdOptions
  );
  const notProductGroup = getSelectedValues(
    FilterGroupsEnum.NotTariffGroupId,
    model.productGroup.list
  );

  const idCriteria = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.Id;
  });
  const fromId =
    idCriteria?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
    )?.value || '';
  const toId =
    idCriteria?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
    )?.value || '';
  const mainContactCity =
    filters.active.find((filter) => {
      return filter.groupId === FilterGroupsEnum.MainContactCity;
    })?.criteria?.[0]?.value || [];

  const birthDate = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.PolicyHolderBirthDate;
  });
  const fromBirthDate = birthDate?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
  )?.value;
  const toBirthDate = birthDate?.criteria?.find(
    ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
  )?.value;

  return (
    <>
      <div className="hidden s:grid w-full grid-cols-5  gap-[24px] items-end">
        <TextField
          value={fromId}
          type="number"
          label={t('filter.fromId')}
          placeholder={t('filter.fromId')}
          inputProps={{ min: 0 }}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleRangeChange({
              from: value.toString(),
              to: toId,
              groupId: FilterGroupsEnum.Id
            });
          }}
        />
        <TextField
          value={policyHolderName}
          label={t('filter.lastName')}
          placeholder={t('filter.lastName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.PolicyHolderName, value.toString());
          }}
        />
        <TextField
          value={postalCode}
          label={t('filter.PLZ')}
          type="number"
          inputProps={{ min: 0 }}
          placeholder={t('filter.PLZ')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactPostalCode, value.toString());
          }}
        />
        <MultiSelectAll
          items={tariffs}
          value={notTariffs}
          label={t('filter.notTariff')}
          onChange={(value) => {
            handleNotTariffFilterChange(value, FilterGroupsEnum.NotTariffGroupId, tariffs);
          }}
        />
        <MultiSelectAll
          items={agentIdOptions}
          value={selectedAgentIds}
          label={t('filter.agentId')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.AgentId,
              SearchOperationEnum.IN,
              agentIdOptions
            );
          }}
        />
        <TextField
          value={toId}
          type="number"
          inputProps={{ min: 0 }}
          label={t('filter.toId')}
          placeholder={t('filter.toId')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleRangeChange({
              from: fromId,
              to: value.toString(),
              groupId: FilterGroupsEnum.Id
            });
          }}
        />
        <TextField
          value={policyHolderFirstName}
          label={t('filter.firstName')}
          placeholder={t('filter.firstName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.PolicyHolderFirstName, value.toString());
          }}
        />
        <TextField
          value={mainContactCity}
          label={t('filter.city')}
          placeholder={t('filter.city')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactCity, value.toString());
          }}
        />
        <MultiSelectAll
          value={hasTariffs}
          items={tariffs}
          label={t('filter.tariff')}
          onChange={(value) => {
            handleTariffFilterChange(value, FilterGroupsEnum.TariffGroupId, tariffs);
          }}
        />
        <DatePicker
          value={validFrom ? new Date(validFrom) : null}
          label={t('filter.validFrom')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: value,
              groupId: FilterGroupsEnum.ValidFrom
            });
          }}
        />
      </div>

      {/* S-viewport */}
      <div className="grid s:hidden w-full grid-cols-3 gap-[24px] items-end">
        <TextField
          value={fromId}
          label={t('filter.fromId')}
          placeholder={t('filter.fromId')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleRangeChange({
              from: value.toString(),
              to: toId,
              groupId: FilterGroupsEnum.Id
            });
          }}
        />
        <DatePicker
          value={fromBirthDate ? new Date(fromBirthDate) : null}
          label={t('filter.fromDateBirth')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: value,
              to: toBirthDate ? new Date(toBirthDate) : null,
              groupId: FilterGroupsEnum.PolicyHolderBirthDate
            });
          }}
        />

        <MultiSelectAll
          items={tariffs}
          value={notTariffs}
          label={t('filter.notTariff')}
          onChange={(value) => {
            handleNotTariffFilterChange(value, FilterGroupsEnum.NotTariffGroupId, tariffs);
          }}
        />
        <TextField
          value={toId}
          label={t('filter.toId')}
          placeholder={t('filter.toId')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleRangeChange({
              from: fromId,
              to: value.toString(),
              groupId: FilterGroupsEnum.Id
            });
          }}
        />
        <DatePicker
          value={toBirthDate ? new Date(toBirthDate) : null}
          label={t('filter.toDateBirth')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: fromBirthDate,
              to: value,
              groupId: FilterGroupsEnum.PolicyHolderBirthDate
            });
          }}
        />
        <MultiSelectAll
          value={hasTariffs}
          items={tariffs}
          label={t('filter.tariff')}
          onChange={(value) => {
            handleTariffFilterChange(value, FilterGroupsEnum.TariffGroupId, tariffs);
          }}
        />
        <TextField
          value={policyHolderName}
          label={t('filter.lastName')}
          placeholder={t('filter.lastName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.PolicyHolderName, value.toString());
          }}
        />
        <TextField
          value={postalCode}
          label={t('filter.PLZ')}
          type="number"
          inputProps={{ min: 0 }}
          placeholder={t('filter.PLZ')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactPostalCode, value.toString());
          }}
        />
        <MultiSelectAll
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.NotTariffGroupId,
              SearchOperationEnum.NOT_IN,
              model.productGroup.list
            );
          }}
          value={notProductGroup}
          label={t('filter.notProductGroup')}
          items={model.productGroup.list}
        />
        <TextField
          value={policyHolderFirstName}
          label={t('filter.firstName')}
          placeholder={t('filter.firstName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.PolicyHolderFirstName, value.toString());
          }}
        />
        <TextField
          value={mainContactCity}
          label={t('filter.city')}
          placeholder={t('filter.city')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.MainContactCity, value.toString());
          }}
        />
        <MultiSelectAll
          value={productGroup}
          label={t('filter.productGroup')}
          items={model.productGroup.list}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.TariffGroupId,
              SearchOperationEnum.IN,
              model.productGroup.list
            );
          }}
        />
      </div>
    </>
  );
};
